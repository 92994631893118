msgid ""
msgstr ""
"POT-Creation-Date: 2024-08-04 21:58+0800\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: en\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

#: src/components/account-block.jsx:136
msgid "Locked"
msgstr ""

#. placeholder {0}: shortenNumber(statusesCount)
#: src/components/account-block.jsx:142
msgid "Posts: {0}"
msgstr ""

#. placeholder {0}: niceDateTime(lastStatusAt, { hideTime: true, })
#: src/components/account-block.jsx:147
msgid "Last posted: {0}"
msgstr ""

#: src/components/account-block.jsx:162
#: src/components/account-info.jsx:667
msgid "Automated"
msgstr ""

#: src/components/account-block.jsx:169
#: src/components/account-info.jsx:672
#: src/components/status.jsx:547
msgid "Group"
msgstr ""

#: src/components/account-block.jsx:179
msgid "Mutual"
msgstr ""

#: src/components/account-block.jsx:183
#: src/components/account-info.jsx:1713
msgid "Requested"
msgstr ""

#: src/components/account-block.jsx:187
#: src/components/account-info.jsx:1704
msgid "Following"
msgstr ""

#: src/components/account-block.jsx:191
#: src/components/account-info.jsx:1095
msgid "Follows you"
msgstr ""

#: src/components/account-block.jsx:199
msgid "{followersCount, plural, one {# follower} other {# followers}}"
msgstr ""

#: src/components/account-block.jsx:208
#: src/components/account-info.jsx:713
msgid "Verified"
msgstr ""

#. placeholder {0}: niceDateTime(createdAt, { hideTime: true, })
#. placeholder {0}: niceDateTime(createdAt, { hideTime: true, })
#: src/components/account-block.jsx:223
#: src/components/account-info.jsx:813
msgid "Joined <0>{0}</0>"
msgstr ""

#: src/components/account-info.jsx:57
msgid "Forever"
msgstr ""

#: src/components/account-info.jsx:380
msgid "Unable to load account."
msgstr ""

#: src/components/account-info.jsx:395
msgid "Go to account page"
msgstr ""

#: src/components/account-info.jsx:424
#: src/components/account-info.jsx:735
#: src/components/account-info.jsx:765
msgid "Followers"
msgstr ""

#. js-lingui-explicit-id
#: src/components/account-info.jsx:427
#: src/components/account-info.jsx:775
#: src/components/account-info.jsx:792
msgid "following.stats"
msgstr "Following"

#: src/components/account-info.jsx:430
#: src/components/account-info.jsx:809
#: src/pages/account-statuses.jsx:479
#: src/pages/search.jsx:328
#: src/pages/search.jsx:475
msgid "Posts"
msgstr ""

#: src/components/account-info.jsx:438
#: src/components/account-info.jsx:1151
#: src/components/compose.jsx:2772
#: src/components/media-alt-modal.jsx:46
#: src/components/media-modal.jsx:358
#: src/components/status.jsx:1770
#: src/components/status.jsx:1787
#: src/components/status.jsx:1912
#: src/components/status.jsx:2519
#: src/components/status.jsx:2522
#: src/pages/account-statuses.jsx:523
#: src/pages/accounts.jsx:110
#: src/pages/hashtag.jsx:200
#: src/pages/list.jsx:158
#: src/pages/public.jsx:115
#: src/pages/scheduled-posts.jsx:87
#: src/pages/status.jsx:1214
#: src/pages/trending.jsx:472
msgid "More"
msgstr ""

#: src/components/account-info.jsx:450
msgid "<0>{displayName}</0> has indicated that their new account is now:"
msgstr ""

#: src/components/account-info.jsx:595
#: src/components/account-info.jsx:1309
msgid "Handle copied"
msgstr "Handle copied"

#: src/components/account-info.jsx:598
#: src/components/account-info.jsx:1312
msgid "Unable to copy handle"
msgstr "Unable to copy handle"

#: src/components/account-info.jsx:604
#: src/components/account-info.jsx:1318
msgid "Copy handle"
msgstr ""

#: src/components/account-info.jsx:610
msgid "Go to original profile page"
msgstr ""

#: src/components/account-info.jsx:628
msgid "View profile image"
msgstr ""

#: src/components/account-info.jsx:645
msgid "View profile header"
msgstr ""

#: src/components/account-info.jsx:662
msgid "In Memoriam"
msgstr ""

#: src/components/account-info.jsx:742
#: src/components/account-info.jsx:783
msgid "This user has chosen to not make this information available."
msgstr "This user has chosen to not make this information available."

#. placeholder {0}: ( postingStats.originals / postingStats.total ).toLocaleString(i18n.locale || undefined, { style: 'percent', })
#. placeholder {1}: ( postingStats.replies / postingStats.total ).toLocaleString(i18n.locale || undefined, { style: 'percent', })
#. placeholder {2}: ( postingStats.boosts / postingStats.total ).toLocaleString(i18n.locale || undefined, { style: 'percent', })
#: src/components/account-info.jsx:838
msgid "{0} original posts, {1} replies, {2} boosts"
msgstr "{0} original posts, {1} replies, {2} boosts"

#. placeholder {0}: postingStats.total
#. placeholder {1}: postingStats.daysSinceLastPost
#. placeholder {2}: postingStats.daysSinceLastPost
#. placeholder {3}: postingStats.daysSinceLastPost
#. placeholder {4}: postingStats.total
#. placeholder {5}: postingStats.total
#. placeholder {6}: postingStats.daysSinceLastPost
#: src/components/account-info.jsx:854
msgid "{0, plural, one {{1, plural, one {Last 1 post in the past 1 day} other {Last 1 post in the past {2} days}}} other {{3, plural, one {Last {4} posts in the past 1 day} other {Last {5} posts in the past {6} days}}}}"
msgstr ""

#. placeholder {0}: postingStats.total
#. placeholder {1}: postingStats.total
#: src/components/account-info.jsx:867
msgid "{0, plural, one {Last 1 post in the past year(s)} other {Last {1} posts in the past year(s)}}"
msgstr ""

#: src/components/account-info.jsx:891
#: src/pages/catchup.jsx:70
msgid "Original"
msgstr ""

#: src/components/account-info.jsx:895
#: src/components/status.jsx:2303
#: src/pages/catchup.jsx:71
#: src/pages/catchup.jsx:1445
#: src/pages/catchup.jsx:2058
#: src/pages/status.jsx:937
#: src/pages/status.jsx:1560
msgid "Replies"
msgstr ""

#: src/components/account-info.jsx:899
#: src/pages/catchup.jsx:72
#: src/pages/catchup.jsx:1447
#: src/pages/catchup.jsx:2070
#: src/pages/settings.jsx:1151
msgid "Boosts"
msgstr ""

#: src/components/account-info.jsx:905
msgid "Post stats unavailable."
msgstr ""

#: src/components/account-info.jsx:936
msgid "View post stats"
msgstr ""

#. placeholder {0}: niceDateTime(lastStatusAt, { hideTime: true, })
#: src/components/account-info.jsx:1099
msgid "Last post: <0>{0}</0>"
msgstr ""

#: src/components/account-info.jsx:1113
msgid "Muted"
msgstr ""

#: src/components/account-info.jsx:1118
msgid "Blocked"
msgstr ""

#: src/components/account-info.jsx:1127
msgid "Private note"
msgstr "Private note"

#: src/components/account-info.jsx:1184
msgid "Mention <0>@{username}</0>"
msgstr ""

#: src/components/account-info.jsx:1196
msgid "Translate bio"
msgstr ""

#: src/components/account-info.jsx:1207
msgid "Edit private note"
msgstr "Edit private note"

#: src/components/account-info.jsx:1207
msgid "Add private note"
msgstr "Add private note"

#: src/components/account-info.jsx:1227
msgid "Notifications enabled for @{username}'s posts."
msgstr "Notifications enabled for @{username}'s posts."

#: src/components/account-info.jsx:1228
msgid " Notifications disabled for @{username}'s posts."
msgstr " Notifications disabled for @{username}'s posts."

#: src/components/account-info.jsx:1240
msgid "Disable notifications"
msgstr "Disable notifications"

#: src/components/account-info.jsx:1241
msgid "Enable notifications"
msgstr "Enable notifications"

#: src/components/account-info.jsx:1258
msgid "Boosts from @{username} enabled."
msgstr "Boosts from @{username} enabled."

#: src/components/account-info.jsx:1259
msgid "Boosts from @{username} disabled."
msgstr "Boosts from @{username} disabled."

#: src/components/account-info.jsx:1270
msgid "Disable boosts"
msgstr "Disable boosts"

#: src/components/account-info.jsx:1270
msgid "Enable boosts"
msgstr "Enable boosts"

#: src/components/account-info.jsx:1286
#: src/components/account-info.jsx:1296
#: src/components/account-info.jsx:1899
msgid "Add/Remove from Lists"
msgstr ""

#: src/components/account-info.jsx:1335
#: src/components/status.jsx:1210
msgid "Link copied"
msgstr ""

#: src/components/account-info.jsx:1338
#: src/components/status.jsx:1213
msgid "Unable to copy link"
msgstr ""

#: src/components/account-info.jsx:1344
#: src/components/shortcuts-settings.jsx:1059
#: src/components/status.jsx:1219
#: src/components/status.jsx:3298
msgid "Copy"
msgstr ""

#: src/components/account-info.jsx:1359
#: src/components/shortcuts-settings.jsx:1077
#: src/components/status.jsx:1235
msgid "Sharing doesn't seem to work."
msgstr ""

#: src/components/account-info.jsx:1365
#: src/components/status.jsx:1241
msgid "Share…"
msgstr ""

#: src/components/account-info.jsx:1385
msgid "Unmuted @{username}"
msgstr "Unmuted @{username}"

#: src/components/account-info.jsx:1397
msgid "Unmute <0>@{username}</0>"
msgstr ""

#: src/components/account-info.jsx:1413
msgid "Mute <0>@{username}</0>…"
msgstr ""

#. placeholder {0}: typeof MUTE_DURATIONS_LABELS[duration] === 'function' ? MUTE_DURATIONS_LABELS[duration]() : _(MUTE_DURATIONS_LABELS[duration])
#: src/components/account-info.jsx:1445
msgid "Muted @{username} for {0}"
msgstr "Muted @{username} for {0}"

#: src/components/account-info.jsx:1457
msgid "Unable to mute @{username}"
msgstr "Unable to mute @{username}"

#: src/components/account-info.jsx:1478
msgid "Remove <0>@{username}</0> from followers?"
msgstr ""

#: src/components/account-info.jsx:1498
msgid "@{username} removed from followers"
msgstr "@{username} removed from followers"

#: src/components/account-info.jsx:1510
msgid "Remove follower…"
msgstr ""

#: src/components/account-info.jsx:1521
msgid "Block <0>@{username}</0>?"
msgstr ""

#: src/components/account-info.jsx:1545
msgid "Unblocked @{username}"
msgstr "Unblocked @{username}"

#: src/components/account-info.jsx:1553
msgid "Blocked @{username}"
msgstr "Blocked @{username}"

#: src/components/account-info.jsx:1561
msgid "Unable to unblock @{username}"
msgstr "Unable to unblock @{username}"

#: src/components/account-info.jsx:1563
msgid "Unable to block @{username}"
msgstr "Unable to block @{username}"

#: src/components/account-info.jsx:1573
msgid "Unblock <0>@{username}</0>"
msgstr ""

#: src/components/account-info.jsx:1582
msgid "Block <0>@{username}</0>…"
msgstr ""

#: src/components/account-info.jsx:1599
msgid "Report <0>@{username}</0>…"
msgstr ""

#: src/components/account-info.jsx:1619
#: src/components/account-info.jsx:2153
msgid "Edit profile"
msgstr ""

#: src/components/account-info.jsx:1655
msgid "Withdraw follow request?"
msgstr "Withdraw follow request?"

#. placeholder {1}: info.acct || info.username
#: src/components/account-info.jsx:1656
msgid "Unfollow @{1}?"
msgstr "Unfollow @{1}?"

#: src/components/account-info.jsx:1707
msgid "Unfollow…"
msgstr ""

#: src/components/account-info.jsx:1716
msgid "Withdraw…"
msgstr ""

#: src/components/account-info.jsx:1723
#: src/components/account-info.jsx:1727
#: src/pages/hashtag.jsx:262
msgid "Follow"
msgstr ""

#: src/components/account-info.jsx:1839
#: src/components/account-info.jsx:1894
#: src/components/account-info.jsx:2028
#: src/components/account-info.jsx:2148
#: src/components/account-sheet.jsx:38
#: src/components/compose.jsx:881
#: src/components/compose.jsx:2728
#: src/components/compose.jsx:3202
#: src/components/compose.jsx:3411
#: src/components/compose.jsx:3641
#: src/components/drafts.jsx:59
#: src/components/embed-modal.jsx:13
#: src/components/generic-accounts.jsx:143
#: src/components/keyboard-shortcuts-help.jsx:42
#: src/components/list-add-edit.jsx:36
#: src/components/media-alt-modal.jsx:34
#: src/components/media-modal.jsx:322
#: src/components/notification-service.jsx:157
#: src/components/report-modal.jsx:75
#: src/components/shortcuts-settings.jsx:230
#: src/components/shortcuts-settings.jsx:583
#: src/components/shortcuts-settings.jsx:783
#: src/components/status.jsx:3022
#: src/components/status.jsx:3262
#: src/components/status.jsx:3762
#: src/pages/accounts.jsx:37
#: src/pages/catchup.jsx:1581
#: src/pages/filters.jsx:224
#: src/pages/list.jsx:276
#: src/pages/notifications.jsx:915
#: src/pages/scheduled-posts.jsx:257
#: src/pages/settings.jsx:78
#: src/pages/status.jsx:1301
msgid "Close"
msgstr ""

#: src/components/account-info.jsx:1844
msgid "Translated Bio"
msgstr ""

#: src/components/account-info.jsx:1939
msgid "Unable to remove from list."
msgstr "Unable to remove from list."

#: src/components/account-info.jsx:1940
msgid "Unable to add to list."
msgstr "Unable to add to list."

#: src/components/account-info.jsx:1959
#: src/pages/lists.jsx:105
msgid "Unable to load lists."
msgstr ""

#: src/components/account-info.jsx:1963
msgid "No lists."
msgstr ""

#: src/components/account-info.jsx:1974
#: src/components/list-add-edit.jsx:40
#: src/pages/lists.jsx:59
msgid "New list"
msgstr ""

#. placeholder {0}: account?.username || account?.acct
#: src/components/account-info.jsx:2033
msgid "Private note about <0>@{0}</0>"
msgstr ""

#: src/components/account-info.jsx:2063
msgid "Unable to update private note."
msgstr "Unable to update private note."

#: src/components/account-info.jsx:2086
#: src/components/account-info.jsx:2384
msgid "Cancel"
msgstr ""

#: src/components/account-info.jsx:2091
msgid "Save & close"
msgstr ""

#: src/components/account-info.jsx:2208
msgid "Unable to update profile."
msgstr "Unable to update profile."

#: src/components/account-info.jsx:2215
msgid "Header picture"
msgstr "Header picture"

#: src/components/account-info.jsx:2267
msgid "Profile picture"
msgstr "Profile picture"

#: src/components/account-info.jsx:2319
#: src/components/list-add-edit.jsx:105
msgid "Name"
msgstr ""

#: src/components/account-info.jsx:2332
msgid "Bio"
msgstr ""

#: src/components/account-info.jsx:2345
msgid "Extra fields"
msgstr ""

#: src/components/account-info.jsx:2351
msgid "Label"
msgstr ""

#: src/components/account-info.jsx:2354
msgid "Content"
msgstr ""

#: src/components/account-info.jsx:2387
#: src/components/list-add-edit.jsx:150
#: src/components/shortcuts-settings.jsx:715
#: src/pages/filters.jsx:554
#: src/pages/notifications.jsx:981
msgid "Save"
msgstr ""

#: src/components/account-info.jsx:2441
msgid "username"
msgstr ""

#: src/components/account-info.jsx:2445
msgid "server domain name"
msgstr ""

#: src/components/background-service.jsx:151
msgid "Cloak mode disabled"
msgstr ""

#: src/components/background-service.jsx:151
msgid "Cloak mode enabled"
msgstr ""

#: src/components/columns.jsx:27
#: src/components/nav-menu.jsx:176
#: src/components/shortcuts-settings.jsx:139
#: src/components/timeline.jsx:439
#: src/pages/catchup.jsx:876
#: src/pages/filters.jsx:89
#: src/pages/followed-hashtags.jsx:41
#: src/pages/home.jsx:53
#: src/pages/notifications.jsx:560
#: src/pages/scheduled-posts.jsx:72
msgid "Home"
msgstr ""

#: src/components/compose-button.jsx:135
#: src/compose.jsx:38
msgid "Compose"
msgstr ""

#: src/components/compose-button.jsx:162
#: src/components/nav-menu.jsx:260
#: src/pages/scheduled-posts.jsx:31
#: src/pages/scheduled-posts.jsx:76
msgid "Scheduled Posts"
msgstr "Scheduled Posts"

#: src/components/compose-button.jsx:175
msgid "Add to thread"
msgstr "Add to thread"

#: src/components/compose.jsx:211
msgid "Take photo or video"
msgstr "Take photo or video"

#: src/components/compose.jsx:212
msgid "Add media"
msgstr "Add media"

#: src/components/compose.jsx:213
msgid "Add custom emoji"
msgstr ""

#: src/components/compose.jsx:214
msgid "Add GIF"
msgstr "Add GIF"

#: src/components/compose.jsx:215
msgid "Add poll"
msgstr ""

#: src/components/compose.jsx:216
msgid "Schedule post"
msgstr "Schedule post"

#: src/components/compose.jsx:415
msgid "You have unsaved changes. Discard this post?"
msgstr "You have unsaved changes. Discard this post?"

#. placeholder {0}: unsupportedFiles.length
#. placeholder {1}: unsupportedFiles[0].name
#. placeholder {2}: lf.format( unsupportedFiles.map((f) => f.name), )
#: src/components/compose.jsx:644
msgid "{0, plural, one {File {1} is not supported.} other {Files {2} are not supported.}}"
msgstr "{0, plural, one {File {1} is not supported.} other {Files {2} are not supported.}}"

#: src/components/compose.jsx:654
#: src/components/compose.jsx:672
#: src/components/compose.jsx:1777
#: src/components/compose.jsx:1902
msgid "{maxMediaAttachments, plural, one {You can only attach up to 1 file.} other {You can only attach up to # files.}}"
msgstr ""

#: src/components/compose.jsx:862
msgid "Pop out"
msgstr "Pop out"

#: src/components/compose.jsx:869
msgid "Minimize"
msgstr "Minimize"

#: src/components/compose.jsx:905
msgid "Looks like you closed the parent window."
msgstr "Looks like you closed the parent window."

#: src/components/compose.jsx:912
msgid "Looks like you already have a compose field open in the parent window and currently publishing. Please wait for it to be done and try again later."
msgstr "Looks like you already have a compose field open in the parent window and currently publishing. Please wait for it to be done and try again later."

#: src/components/compose.jsx:917
msgid "Looks like you already have a compose field open in the parent window. Popping in this window will discard the changes you made in the parent window. Continue?"
msgstr "Looks like you already have a compose field open in the parent window. Popping in this window will discard the changes you made in the parent window. Continue?"

#: src/components/compose.jsx:960
msgid "Pop in"
msgstr "Pop in"

#. placeholder {0}: replyToStatus.account.acct || replyToStatus.account.username
#. placeholder {1}: rtf.format(-replyToStatusMonthsAgo, 'month')
#: src/components/compose.jsx:970
msgid "Replying to @{0}’s post (<0>{1}</0>)"
msgstr ""

#. placeholder {0}: replyToStatus.account.acct || replyToStatus.account.username
#: src/components/compose.jsx:980
msgid "Replying to @{0}’s post"
msgstr ""

#: src/components/compose.jsx:993
msgid "Editing source post"
msgstr ""

#: src/components/compose.jsx:1046
msgid "Poll must have at least 2 options"
msgstr "Poll must have at least 2 options"

#: src/components/compose.jsx:1050
msgid "Some poll choices are empty"
msgstr "Some poll choices are empty"

#: src/components/compose.jsx:1063
msgid "Some media have no descriptions. Continue?"
msgstr "Some media have no descriptions. Continue?"

#: src/components/compose.jsx:1115
msgid "Attachment #{i} failed"
msgstr "Attachment #{i} failed"

#: src/components/compose.jsx:1211
#: src/components/status.jsx:2098
#: src/components/timeline.jsx:989
msgid "Content warning"
msgstr ""

#: src/components/compose.jsx:1227
msgid "Content warning or sensitive media"
msgstr "Content warning or sensitive media"

#: src/components/compose.jsx:1263
#: src/components/status.jsx:94
#: src/pages/settings.jsx:306
msgid "Public"
msgstr ""

#: src/components/compose.jsx:1268
#: src/components/nav-menu.jsx:344
#: src/components/shortcuts-settings.jsx:165
#: src/components/status.jsx:95
msgid "Local"
msgstr ""

#: src/components/compose.jsx:1272
#: src/components/status.jsx:96
#: src/pages/settings.jsx:309
msgid "Unlisted"
msgstr ""

#: src/components/compose.jsx:1275
#: src/components/status.jsx:97
#: src/pages/settings.jsx:312
msgid "Followers only"
msgstr ""

#: src/components/compose.jsx:1278
#: src/components/status.jsx:98
#: src/components/status.jsx:1976
msgid "Private mention"
msgstr ""

#: src/components/compose.jsx:1287
msgid "Post your reply"
msgstr "Post your reply"

#: src/components/compose.jsx:1289
msgid "Edit your post"
msgstr "Edit your post"

#: src/components/compose.jsx:1290
msgid "What are you doing?"
msgstr "What are you doing?"

#: src/components/compose.jsx:1368
msgid "Mark media as sensitive"
msgstr ""

#: src/components/compose.jsx:1405
msgid "Posting on <0/>"
msgstr "Posting on <0/>"

#: src/components/compose.jsx:1436
#: src/components/compose.jsx:3260
#: src/components/shortcuts-settings.jsx:715
#: src/pages/list.jsx:362
msgid "Add"
msgstr ""

#: src/components/compose.jsx:1662
msgid "Schedule"
msgstr "Schedule"

#: src/components/compose.jsx:1664
#: src/components/keyboard-shortcuts-help.jsx:154
#: src/components/status.jsx:984
#: src/components/status.jsx:1750
#: src/components/status.jsx:1751
#: src/components/status.jsx:2423
msgid "Reply"
msgstr ""

#: src/components/compose.jsx:1666
msgid "Update"
msgstr "Update"

#: src/components/compose.jsx:1667
msgctxt "Submit button in composer"
msgid "Post"
msgstr "Post"

#: src/components/compose.jsx:1789
msgid "Downloading GIF…"
msgstr "Downloading GIF…"

#: src/components/compose.jsx:1817
msgid "Failed to download GIF"
msgstr "Failed to download GIF"

#: src/components/compose.jsx:2032
#: src/components/compose.jsx:2109
#: src/components/nav-menu.jsx:239
msgid "More…"
msgstr ""

#: src/components/compose.jsx:2541
msgid "Uploaded"
msgstr ""

#: src/components/compose.jsx:2554
msgid "Image description"
msgstr "Image description"

#: src/components/compose.jsx:2555
msgid "Video description"
msgstr "Video description"

#: src/components/compose.jsx:2556
msgid "Audio description"
msgstr "Audio description"

#. placeholder {0}: prettyBytes( imageSize, )
#. placeholder {1}: prettyBytes(imageSizeLimit)
#: src/components/compose.jsx:2592
msgid "File size too large. Uploading might encounter issues. Try reduce the file size from {0} to {1} or lower."
msgstr "File size too large. Uploading might encounter issues. Try reduce the file size from {0} to {1} or lower."

#. placeholder {2}: i18n.number( width, )
#. placeholder {3}: i18n.number(height)
#. placeholder {4}: i18n.number(newWidth)
#. placeholder {5}: i18n.number( newHeight, )
#: src/components/compose.jsx:2604
msgid "Dimension too large. Uploading might encounter issues. Try reduce dimension from {2}×{3}px to {4}×{5}px."
msgstr "Dimension too large. Uploading might encounter issues. Try reduce dimension from {2}×{3}px to {4}×{5}px."

#. placeholder {6}: prettyBytes( videoSize, )
#. placeholder {7}: prettyBytes(videoSizeLimit)
#: src/components/compose.jsx:2612
msgid "File size too large. Uploading might encounter issues. Try reduce the file size from {6} to {7} or lower."
msgstr "File size too large. Uploading might encounter issues. Try reduce the file size from {6} to {7} or lower."

#. placeholder {8}: i18n.number( width, )
#. placeholder {9}: i18n.number(height)
#. placeholder {10}: i18n.number(newWidth)
#. placeholder {11}: i18n.number( newHeight, )
#: src/components/compose.jsx:2624
msgid "Dimension too large. Uploading might encounter issues. Try reduce dimension from {8}×{9}px to {10}×{11}px."
msgstr "Dimension too large. Uploading might encounter issues. Try reduce dimension from {8}×{9}px to {10}×{11}px."

#: src/components/compose.jsx:2632
msgid "Frame rate too high. Uploading might encounter issues."
msgstr "Frame rate too high. Uploading might encounter issues."

#: src/components/compose.jsx:2692
#: src/components/compose.jsx:2942
#: src/components/shortcuts-settings.jsx:726
#: src/pages/catchup.jsx:1074
#: src/pages/filters.jsx:412
msgid "Remove"
msgstr ""

#: src/components/compose.jsx:2709
#: src/compose.jsx:84
msgid "Error"
msgstr ""

#: src/components/compose.jsx:2734
msgid "Edit image description"
msgstr "Edit image description"

#: src/components/compose.jsx:2735
msgid "Edit video description"
msgstr "Edit video description"

#: src/components/compose.jsx:2736
msgid "Edit audio description"
msgstr "Edit audio description"

#: src/components/compose.jsx:2781
#: src/components/compose.jsx:2830
msgid "Generating description. Please wait…"
msgstr "Generating description. Please wait…"

#. placeholder {12}: e.message
#: src/components/compose.jsx:2801
msgid "Failed to generate description: {12}"
msgstr "Failed to generate description: {12}"

#: src/components/compose.jsx:2802
msgid "Failed to generate description"
msgstr "Failed to generate description"

#: src/components/compose.jsx:2814
#: src/components/compose.jsx:2820
#: src/components/compose.jsx:2866
msgid "Generate description…"
msgstr ""

#. placeholder {13}: e?.message ? `: ${e.message}` : ''
#: src/components/compose.jsx:2853
msgid "Failed to generate description{13}"
msgstr "Failed to generate description{13}"

#. placeholder {0}: localeCode2Text(lang)
#: src/components/compose.jsx:2868
msgid "({0}) <0>— experimental</0>"
msgstr ""

#: src/components/compose.jsx:2887
msgid "Done"
msgstr ""

#. placeholder {0}: i + 1
#: src/components/compose.jsx:2923
msgid "Choice {0}"
msgstr "Choice {0}"

#: src/components/compose.jsx:2970
msgid "Multiple choices"
msgstr ""

#: src/components/compose.jsx:2973
msgid "Duration"
msgstr ""

#: src/components/compose.jsx:3004
msgid "Remove poll"
msgstr ""

#: src/components/compose.jsx:3219
msgid "Search accounts"
msgstr "Search accounts"

#: src/components/compose.jsx:3273
#: src/components/generic-accounts.jsx:228
msgid "Error loading accounts"
msgstr ""

#: src/components/compose.jsx:3417
msgid "Custom emojis"
msgstr ""

#: src/components/compose.jsx:3437
msgid "Search emoji"
msgstr "Search emoji"

#: src/components/compose.jsx:3468
msgid "Error loading custom emojis"
msgstr ""

#: src/components/compose.jsx:3479
msgid "Recently used"
msgstr "Recently used"

#: src/components/compose.jsx:3480
msgid "Others"
msgstr "Others"

#. placeholder {0}: i18n.number(emojis.length - max)
#: src/components/compose.jsx:3518
msgid "{0} more…"
msgstr ""

#: src/components/compose.jsx:3656
msgid "Search GIFs"
msgstr "Search GIFs"

#: src/components/compose.jsx:3671
msgid "Powered by GIPHY"
msgstr "Powered by GIPHY"

#: src/components/compose.jsx:3679
msgid "Type to search GIFs"
msgstr ""

#: src/components/compose.jsx:3777
#: src/components/media-modal.jsx:464
#: src/components/timeline.jsx:893
msgid "Previous"
msgstr ""

#: src/components/compose.jsx:3795
#: src/components/media-modal.jsx:483
#: src/components/timeline.jsx:910
msgid "Next"
msgstr ""

#: src/components/compose.jsx:3812
msgid "Error loading GIFs"
msgstr ""

#: src/components/drafts.jsx:64
#: src/pages/settings.jsx:693
msgid "Unsent drafts"
msgstr ""

#: src/components/drafts.jsx:69
msgid "Looks like you have unsent drafts. Let's continue where you left off."
msgstr ""

#: src/components/drafts.jsx:103
msgid "Delete this draft?"
msgstr ""

#: src/components/drafts.jsx:118
msgid "Error deleting draft! Please try again."
msgstr ""

#: src/components/drafts.jsx:128
#: src/components/list-add-edit.jsx:186
#: src/components/status.jsx:1385
#: src/pages/filters.jsx:587
#: src/pages/scheduled-posts.jsx:367
msgid "Delete…"
msgstr ""

#: src/components/drafts.jsx:147
msgid "Error fetching reply-to status!"
msgstr ""

#: src/components/drafts.jsx:172
msgid "Delete all drafts?"
msgstr ""

#: src/components/drafts.jsx:190
msgid "Error deleting drafts! Please try again."
msgstr ""

#: src/components/drafts.jsx:202
msgid "Delete all…"
msgstr ""

#: src/components/drafts.jsx:210
msgid "No drafts found."
msgstr ""

#: src/components/drafts.jsx:247
#: src/pages/catchup.jsx:1929
msgid "Poll"
msgstr ""

#: src/components/drafts.jsx:250
#: src/pages/account-statuses.jsx:360
msgid "Media"
msgstr ""

#: src/components/embed-modal.jsx:18
msgid "Open in new window"
msgstr ""

#: src/components/follow-request-buttons.jsx:43
#: src/pages/notifications.jsx:965
msgid "Accept"
msgstr ""

#: src/components/follow-request-buttons.jsx:69
msgid "Reject"
msgstr ""

#: src/components/follow-request-buttons.jsx:76
#: src/pages/notifications.jsx:1249
msgid "Accepted"
msgstr ""

#: src/components/follow-request-buttons.jsx:80
msgid "Rejected"
msgstr ""

#: src/components/generic-accounts.jsx:146
#: src/components/notification.jsx:445
#: src/pages/accounts.jsx:42
#: src/pages/search.jsx:318
#: src/pages/search.jsx:351
msgid "Accounts"
msgstr ""

#: src/components/generic-accounts.jsx:206
#: src/components/timeline.jsx:521
#: src/pages/list.jsx:295
#: src/pages/notifications.jsx:895
#: src/pages/search.jsx:545
#: src/pages/status.jsx:1334
msgid "Show more…"
msgstr ""

#: src/components/generic-accounts.jsx:211
#: src/components/timeline.jsx:526
#: src/pages/search.jsx:550
msgid "The end."
msgstr ""

#: src/components/generic-accounts.jsx:232
msgid "Nothing to show"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:46
#: src/components/nav-menu.jsx:363
#: src/pages/catchup.jsx:1619
msgid "Keyboard shortcuts"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:54
msgid "Keyboard shortcuts help"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:58
#: src/pages/catchup.jsx:1644
msgid "Next post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:62
#: src/pages/catchup.jsx:1652
msgid "Previous post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:66
msgid "Skip carousel to next post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:68
msgid "<0>Shift</0> + <1>j</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:74
msgid "Skip carousel to previous post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:76
msgid "<0>Shift</0> + <1>k</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:82
msgid "Load new posts"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:86
#: src/pages/catchup.jsx:1676
msgid "Open post details"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:88
msgid "<0>Enter</0> or <1>o</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:95
msgid "Expand content warning or<0/>toggle expanded/collapsed thread"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:104
msgid "Close post or dialogs"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:106
msgid "<0>Esc</0> or <1>Backspace</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:112
msgid "Focus column in multi-column mode"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:114
msgid "<0>1</0> to <1>9</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:120
msgid "Focus next column in multi-column mode"
msgstr "Focus next column in multi-column mode"

#: src/components/keyboard-shortcuts-help.jsx:124
msgid "Focus previous column in multi-column mode"
msgstr "Focus previous column in multi-column mode"

#: src/components/keyboard-shortcuts-help.jsx:128
msgid "Compose new post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:132
msgid "Compose new post (new window)"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:135
msgid "<0>Shift</0> + <1>c</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:141
msgid "Send post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:143
msgid "<0>Ctrl</0> + <1>Enter</1> or <2>⌘</2> + <3>Enter</3>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:150
#: src/components/nav-menu.jsx:332
#: src/components/search-form.jsx:73
#: src/components/shortcuts-settings.jsx:52
#: src/components/shortcuts-settings.jsx:179
#: src/pages/search.jsx:46
#: src/pages/search.jsx:300
msgid "Search"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:158
msgid "Reply (new window)"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:161
msgid "<0>Shift</0> + <1>r</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:167
msgid "Like (favourite)"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:169
msgid "<0>l</0> or <1>f</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:175
#: src/components/status.jsx:992
#: src/components/status.jsx:2450
#: src/components/status.jsx:2473
#: src/components/status.jsx:2474
msgid "Boost"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:177
msgid "<0>Shift</0> + <1>b</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:183
#: src/components/status.jsx:1055
#: src/components/status.jsx:2498
#: src/components/status.jsx:2499
msgid "Bookmark"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:187
msgid "Toggle Cloak mode"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:189
msgid "<0>Shift</0> + <1>Alt</1> + <2>k</2>"
msgstr ""

#: src/components/list-add-edit.jsx:40
msgid "Edit list"
msgstr ""

#: src/components/list-add-edit.jsx:96
msgid "Unable to edit list."
msgstr ""

#: src/components/list-add-edit.jsx:97
msgid "Unable to create list."
msgstr ""

#: src/components/list-add-edit.jsx:125
msgid "Show replies to list members"
msgstr ""

#: src/components/list-add-edit.jsx:128
msgid "Show replies to people I follow"
msgstr ""

#: src/components/list-add-edit.jsx:131
msgid "Don't show replies"
msgstr ""

#: src/components/list-add-edit.jsx:144
msgid "Hide posts on this list from Home/Following"
msgstr ""

#: src/components/list-add-edit.jsx:150
#: src/pages/filters.jsx:554
msgid "Create"
msgstr ""

#: src/components/list-add-edit.jsx:157
msgid "Delete this list?"
msgstr ""

#: src/components/list-add-edit.jsx:176
msgid "Unable to delete list."
msgstr ""

#: src/components/media-alt-modal.jsx:39
#: src/components/media.jsx:51
msgid "Media description"
msgstr ""

#: src/components/media-alt-modal.jsx:58
#: src/components/status.jsx:1099
#: src/components/status.jsx:1126
#: src/components/translation-block.jsx:215
msgid "Translate"
msgstr ""

#: src/components/media-alt-modal.jsx:69
#: src/components/status.jsx:1113
#: src/components/status.jsx:1140
msgid "Speak"
msgstr ""

#: src/components/media-modal.jsx:369
msgid "Open original media in new window"
msgstr ""

#: src/components/media-modal.jsx:373
msgid "Open original media"
msgstr ""

#: src/components/media-modal.jsx:389
msgid "Attempting to describe image. Please wait…"
msgstr ""

#: src/components/media-modal.jsx:404
msgid "Failed to describe image"
msgstr ""

#: src/components/media-modal.jsx:414
msgid "Describe image…"
msgstr ""

#: src/components/media-modal.jsx:438
msgid "View post"
msgstr ""

#: src/components/media-post.jsx:128
msgid "Sensitive media"
msgstr ""

#: src/components/media-post.jsx:133
msgid "Filtered: {filterTitleStr}"
msgstr ""

#: src/components/media-post.jsx:134
#: src/components/status.jsx:3592
#: src/components/status.jsx:3688
#: src/components/status.jsx:3766
#: src/components/timeline.jsx:978
#: src/pages/catchup.jsx:75
#: src/pages/catchup.jsx:1877
msgid "Filtered"
msgstr ""

#: src/components/media.jsx:414
msgid "Open file"
msgstr "Open file"

#: src/components/modals.jsx:75
msgid "Post scheduled"
msgstr "Post scheduled"

#: src/components/modals.jsx:76
msgid "Post published. Check it out."
msgstr ""

#: src/components/modals.jsx:78
msgid "Reply scheduled"
msgstr "Reply scheduled"

#: src/components/modals.jsx:79
msgid "Reply posted. Check it out."
msgstr ""

#: src/components/modals.jsx:80
msgid "Post updated. Check it out."
msgstr ""

#: src/components/nav-menu.jsx:118
msgid "Menu"
msgstr ""

#: src/components/nav-menu.jsx:154
msgid "Reload page now to update?"
msgstr ""

#: src/components/nav-menu.jsx:166
msgid "New update available…"
msgstr ""

#. js-lingui-explicit-id
#: src/components/nav-menu.jsx:185
#: src/components/shortcuts-settings.jsx:140
#: src/pages/following.jsx:23
#: src/pages/following.jsx:142
msgid "following.title"
msgstr "Following"

#: src/components/nav-menu.jsx:192
#: src/pages/catchup.jsx:871
msgid "Catch-up"
msgstr ""

#: src/components/nav-menu.jsx:199
#: src/components/shortcuts-settings.jsx:58
#: src/components/shortcuts-settings.jsx:146
#: src/pages/home.jsx:225
#: src/pages/mentions.jsx:21
#: src/pages/mentions.jsx:168
#: src/pages/settings.jsx:1143
#: src/pages/trending.jsx:382
msgid "Mentions"
msgstr ""

#: src/components/nav-menu.jsx:206
#: src/components/shortcuts-settings.jsx:49
#: src/components/shortcuts-settings.jsx:152
#: src/pages/filters.jsx:23
#: src/pages/home.jsx:85
#: src/pages/home.jsx:185
#: src/pages/notifications.jsx:113
#: src/pages/notifications.jsx:564
msgid "Notifications"
msgstr ""

#: src/components/nav-menu.jsx:209
msgid "New"
msgstr ""

#: src/components/nav-menu.jsx:220
msgid "Profile"
msgstr ""

#: src/components/nav-menu.jsx:228
#: src/components/shortcuts-settings.jsx:54
#: src/components/shortcuts-settings.jsx:195
#: src/pages/bookmarks.jsx:12
#: src/pages/bookmarks.jsx:24
msgid "Bookmarks"
msgstr ""

#: src/components/nav-menu.jsx:248
#: src/components/shortcuts-settings.jsx:55
#: src/components/shortcuts-settings.jsx:201
#: src/pages/catchup.jsx:1446
#: src/pages/catchup.jsx:2064
#: src/pages/favourites.jsx:12
#: src/pages/favourites.jsx:24
#: src/pages/settings.jsx:1147
msgid "Likes"
msgstr ""

#: src/components/nav-menu.jsx:254
#: src/pages/followed-hashtags.jsx:15
#: src/pages/followed-hashtags.jsx:45
msgid "Followed Hashtags"
msgstr ""

#: src/components/nav-menu.jsx:268
#: src/pages/account-statuses.jsx:326
#: src/pages/filters.jsx:54
#: src/pages/filters.jsx:93
#: src/pages/hashtag.jsx:340
msgid "Filters"
msgstr ""

#: src/components/nav-menu.jsx:276
msgid "Muted users"
msgstr ""

#: src/components/nav-menu.jsx:284
msgid "Muted users…"
msgstr ""

#: src/components/nav-menu.jsx:291
msgid "Blocked users"
msgstr ""

#: src/components/nav-menu.jsx:299
msgid "Blocked users…"
msgstr ""

#: src/components/nav-menu.jsx:311
msgid "Accounts…"
msgstr ""

#: src/components/nav-menu.jsx:321
#: src/pages/login.jsx:27
#: src/pages/login.jsx:190
#: src/pages/status.jsx:837
#: src/pages/welcome.jsx:65
msgid "Log in"
msgstr ""

#: src/components/nav-menu.jsx:338
#: src/components/shortcuts-settings.jsx:57
#: src/components/shortcuts-settings.jsx:172
#: src/pages/trending.jsx:442
msgid "Trending"
msgstr ""

#: src/components/nav-menu.jsx:350
#: src/components/shortcuts-settings.jsx:165
msgid "Federated"
msgstr ""

#: src/components/nav-menu.jsx:373
msgid "Shortcuts / Columns…"
msgstr ""

#: src/components/nav-menu.jsx:383
#: src/components/nav-menu.jsx:397
msgid "Settings…"
msgstr ""

#: src/components/nav-menu.jsx:427
#: src/components/nav-menu.jsx:454
#: src/components/shortcuts-settings.jsx:50
#: src/components/shortcuts-settings.jsx:158
#: src/pages/list.jsx:127
#: src/pages/lists.jsx:17
#: src/pages/lists.jsx:51
msgid "Lists"
msgstr ""

#: src/components/nav-menu.jsx:435
#: src/components/shortcuts.jsx:214
#: src/pages/list.jsx:134
msgid "All Lists"
msgstr ""

#: src/components/notification-service.jsx:161
msgid "Notification"
msgstr ""

#: src/components/notification-service.jsx:167
msgid "This notification is from your other account."
msgstr ""

#: src/components/notification-service.jsx:196
msgid "View all notifications"
msgstr ""

#: src/components/notification.jsx:71
msgid "{account} reacted to your post with {emojiObject}"
msgstr ""

#: src/components/notification.jsx:78
msgid "{account} published a post."
msgstr ""

#. placeholder {0}: shortenNumber(count)
#. placeholder {1}: shortenNumber(count)
#: src/components/notification.jsx:86
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted your reply.} other {{account} boosted your post.}}} other {{account} boosted {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted your reply.} other {<2><3>{1}</3> people</2> boosted your post.}}}}"
msgstr ""

#. placeholder {0}: shortenNumber(count)
#: src/components/notification.jsx:129
msgid "{count, plural, =1 {{account} followed you.} other {<0><1>{0}</1> people</0> followed you.}}"
msgstr ""

#: src/components/notification.jsx:143
msgid "{account} requested to follow you."
msgstr ""

#. placeholder {0}: shortenNumber(count)
#. placeholder {1}: shortenNumber(count)
#: src/components/notification.jsx:152
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} liked your reply.} other {{account} liked your post.}}} other {{account} liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> liked your reply.} other {<2><3>{1}</3> people</2> liked your post.}}}}"
msgstr ""

#: src/components/notification.jsx:194
msgid "A poll you have voted in or created has ended."
msgstr ""

#: src/components/notification.jsx:195
msgid "A poll you have created has ended."
msgstr ""

#: src/components/notification.jsx:196
msgid "A poll you have voted in has ended."
msgstr ""

#: src/components/notification.jsx:197
msgid "A post you interacted with has been edited."
msgstr ""

#. placeholder {0}: shortenNumber(count)
#. placeholder {1}: shortenNumber(count)
#: src/components/notification.jsx:205
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted & liked your reply.} other {{account} boosted & liked your post.}}} other {{account} boosted & liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted & liked your reply.} other {<2><3>{1}</3> people</2> boosted & liked your post.}}}}"
msgstr ""

#: src/components/notification.jsx:247
msgid "{account} signed up."
msgstr ""

#: src/components/notification.jsx:249
msgid "{account} reported {targetAccount}"
msgstr ""

#: src/components/notification.jsx:254
msgid "Lost connections with <0>{name}</0>."
msgstr ""

#: src/components/notification.jsx:260
msgid "Moderation warning"
msgstr ""

#: src/components/notification.jsx:265
msgid "Your {year} #Wrapstodon is here!"
msgstr "Your {year} #Wrapstodon is here!"

#: src/components/notification.jsx:271
msgid "An admin from <0>{from}</0> has suspended <1>{targetName}</1>, which means you can no longer receive updates from them or interact with them."
msgstr ""

#: src/components/notification.jsx:277
msgid "An admin from <0>{from}</0> has blocked <1>{targetName}</1>. Affected followers: {followersCount}, followings: {followingCount}."
msgstr ""

#: src/components/notification.jsx:283
msgid "You have blocked <0>{targetName}</0>. Removed followers: {followersCount}, followings: {followingCount}."
msgstr ""

#: src/components/notification.jsx:291
msgid "Your account has received a moderation warning."
msgstr ""

#: src/components/notification.jsx:292
msgid "Your account has been disabled."
msgstr ""

#: src/components/notification.jsx:293
msgid "Some of your posts have been marked as sensitive."
msgstr ""

#: src/components/notification.jsx:294
msgid "Some of your posts have been deleted."
msgstr ""

#: src/components/notification.jsx:295
msgid "Your posts will be marked as sensitive from now on."
msgstr ""

#: src/components/notification.jsx:296
msgid "Your account has been limited."
msgstr ""

#: src/components/notification.jsx:297
msgid "Your account has been suspended."
msgstr ""

#: src/components/notification.jsx:372
msgid "[Unknown notification type: {type}]"
msgstr ""

#: src/components/notification.jsx:441
#: src/components/status.jsx:1069
#: src/components/status.jsx:1079
msgid "Boosted/Liked by…"
msgstr ""

#: src/components/notification.jsx:442
msgid "Liked by…"
msgstr ""

#: src/components/notification.jsx:443
msgid "Boosted by…"
msgstr ""

#: src/components/notification.jsx:444
msgid "Followed by…"
msgstr ""

#: src/components/notification.jsx:515
#: src/components/notification.jsx:531
msgid "Learn more <0/>"
msgstr ""

#: src/components/notification.jsx:540
msgid "View #Wrapstodon"
msgstr "View #Wrapstodon"

#: src/components/notification.jsx:770
#: src/components/status.jsx:297
msgid "Read more →"
msgstr ""

#: src/components/poll.jsx:113
msgid "Voted"
msgstr ""

#: src/components/poll.jsx:119
msgid "{optionVotesCount, plural, one {# vote} other {# votes}}"
msgstr "{optionVotesCount, plural, one {# vote} other {# votes}}"

#: src/components/poll.jsx:139
#: src/components/poll.jsx:222
#: src/components/poll.jsx:226
msgid "Hide results"
msgstr ""

#: src/components/poll.jsx:188
msgid "Vote"
msgstr ""

#: src/components/poll.jsx:208
#: src/components/poll.jsx:210
#: src/pages/scheduled-posts.jsx:98
#: src/pages/status.jsx:1203
#: src/pages/status.jsx:1226
msgid "Refresh"
msgstr ""

#: src/components/poll.jsx:222
#: src/components/poll.jsx:226
msgid "Show results"
msgstr ""

#. placeholder {0}: shortenNumber(votesCount)
#. placeholder {1}: shortenNumber(votesCount)
#: src/components/poll.jsx:231
msgid "{votesCount, plural, one {<0>{0}</0> vote} other {<1>{1}</1> votes}}"
msgstr "{votesCount, plural, one {<0>{0}</0> vote} other {<1>{1}</1> votes}}"

#. placeholder {0}: shortenNumber(votersCount)
#. placeholder {1}: shortenNumber(votersCount)
#: src/components/poll.jsx:248
msgid "{votersCount, plural, one {<0>{0}</0> voter} other {<1>{1}</1> voters}}"
msgstr ""

#: src/components/poll.jsx:268
msgid "Ended <0/>"
msgstr ""

#: src/components/poll.jsx:272
msgid "Ended"
msgstr ""

#: src/components/poll.jsx:275
msgid "Ending <0/>"
msgstr ""

#: src/components/poll.jsx:279
msgid "Ending"
msgstr ""

#. Relative time in seconds, as short as possible
#. placeholder {0}: seconds < 1 ? 1 : Math.floor(seconds)
#: src/components/relative-time.jsx:59
msgid "{0}s"
msgstr ""

#. Relative time in minutes, as short as possible
#. placeholder {0}: Math.floor(seconds / minute)
#: src/components/relative-time.jsx:64
msgid "{0}m"
msgstr ""

#. Relative time in hours, as short as possible
#. placeholder {0}: Math.floor(seconds / hour)
#: src/components/relative-time.jsx:69
msgid "{0}h"
msgstr ""

#: src/components/report-modal.jsx:29
msgid "Spam"
msgstr ""

#: src/components/report-modal.jsx:30
msgid "Malicious links, fake engagement, or repetitive replies"
msgstr ""

#: src/components/report-modal.jsx:33
msgid "Illegal"
msgstr ""

#: src/components/report-modal.jsx:34
msgid "Violates the law of your or the server's country"
msgstr ""

#: src/components/report-modal.jsx:37
msgid "Server rule violation"
msgstr ""

#: src/components/report-modal.jsx:38
msgid "Breaks specific server rules"
msgstr ""

#: src/components/report-modal.jsx:39
msgid "Violation"
msgstr ""

#: src/components/report-modal.jsx:42
msgid "Other"
msgstr ""

#: src/components/report-modal.jsx:43
msgid "Issue doesn't fit other categories"
msgstr ""

#: src/components/report-modal.jsx:68
msgid "Report Post"
msgstr ""

#: src/components/report-modal.jsx:68
msgid "Report @{username}"
msgstr ""

#: src/components/report-modal.jsx:104
msgid "Pending review"
msgstr ""

#: src/components/report-modal.jsx:146
msgid "Post reported"
msgstr ""

#: src/components/report-modal.jsx:146
msgid "Profile reported"
msgstr ""

#: src/components/report-modal.jsx:154
msgid "Unable to report post"
msgstr ""

#: src/components/report-modal.jsx:155
msgid "Unable to report profile"
msgstr ""

#: src/components/report-modal.jsx:163
msgid "What's the issue with this post?"
msgstr ""

#: src/components/report-modal.jsx:164
msgid "What's the issue with this profile?"
msgstr ""

#: src/components/report-modal.jsx:233
msgid "Additional info"
msgstr ""

#: src/components/report-modal.jsx:256
msgid "Forward to <0>{domain}</0>"
msgstr ""

#: src/components/report-modal.jsx:266
msgid "Send Report"
msgstr ""

#: src/components/report-modal.jsx:275
msgid "Muted {username}"
msgstr ""

#: src/components/report-modal.jsx:278
msgid "Unable to mute {username}"
msgstr ""

#: src/components/report-modal.jsx:283
msgid "Send Report <0>+ Mute profile</0>"
msgstr ""

#: src/components/report-modal.jsx:294
msgid "Blocked {username}"
msgstr ""

#: src/components/report-modal.jsx:297
msgid "Unable to block {username}"
msgstr ""

#: src/components/report-modal.jsx:302
msgid "Send Report <0>+ Block profile</0>"
msgstr ""

#: src/components/search-form.jsx:203
msgid "{query} <0>‒ accounts, hashtags & posts</0>"
msgstr ""

#: src/components/search-form.jsx:216
msgid "Posts with <0>{query}</0>"
msgstr ""

#. placeholder {0}: query.replace(/^#/, '')
#: src/components/search-form.jsx:228
msgid "Posts tagged with <0>#{0}</0>"
msgstr ""

#: src/components/search-form.jsx:242
msgid "Look up <0>{query}</0>"
msgstr ""

#: src/components/search-form.jsx:253
msgid "Accounts with <0>{query}</0>"
msgstr ""

#: src/components/shortcuts-settings.jsx:48
msgid "Home / Following"
msgstr ""

#: src/components/shortcuts-settings.jsx:51
msgid "Public (Local / Federated)"
msgstr ""

#: src/components/shortcuts-settings.jsx:53
msgid "Account"
msgstr ""

#: src/components/shortcuts-settings.jsx:56
msgid "Hashtag"
msgstr ""

#: src/components/shortcuts-settings.jsx:63
msgid "List ID"
msgstr ""

#: src/components/shortcuts-settings.jsx:70
msgid "Local only"
msgstr ""

#: src/components/shortcuts-settings.jsx:75
#: src/components/shortcuts-settings.jsx:84
#: src/components/shortcuts-settings.jsx:122
#: src/pages/login.jsx:194
msgid "Instance"
msgstr ""

#: src/components/shortcuts-settings.jsx:78
#: src/components/shortcuts-settings.jsx:87
#: src/components/shortcuts-settings.jsx:125
msgid "Optional, e.g. mastodon.social"
msgstr ""

#: src/components/shortcuts-settings.jsx:93
msgid "Search term"
msgstr ""

#: src/components/shortcuts-settings.jsx:96
msgid "Optional, unless for multi-column mode"
msgstr ""

#: src/components/shortcuts-settings.jsx:113
msgid "e.g. PixelArt (Max 5, space-separated)"
msgstr ""

#: src/components/shortcuts-settings.jsx:117
#: src/pages/hashtag.jsx:356
msgid "Media only"
msgstr ""

#: src/components/shortcuts-settings.jsx:235
#: src/components/shortcuts.jsx:191
msgid "Shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:243
msgid "beta"
msgstr ""

#: src/components/shortcuts-settings.jsx:249
msgid "Specify a list of shortcuts that'll appear as:"
msgstr ""

#: src/components/shortcuts-settings.jsx:255
msgid "Floating button"
msgstr ""

#: src/components/shortcuts-settings.jsx:260
msgid "Tab/Menu bar"
msgstr ""

#: src/components/shortcuts-settings.jsx:265
msgid "Multi-column"
msgstr ""

#: src/components/shortcuts-settings.jsx:332
msgid "Not available in current view mode"
msgstr ""

#: src/components/shortcuts-settings.jsx:351
msgid "Move up"
msgstr ""

#: src/components/shortcuts-settings.jsx:367
msgid "Move down"
msgstr ""

#: src/components/shortcuts-settings.jsx:379
#: src/components/status.jsx:1347
#: src/pages/list.jsx:171
msgid "Edit"
msgstr ""

#: src/components/shortcuts-settings.jsx:400
msgid "Add more than one shortcut/column to make this work."
msgstr ""

#: src/components/shortcuts-settings.jsx:411
msgid "No columns yet. Tap on the Add column button."
msgstr ""

#: src/components/shortcuts-settings.jsx:412
msgid "No shortcuts yet. Tap on the Add shortcut button."
msgstr ""

#: src/components/shortcuts-settings.jsx:415
msgid "Not sure what to add?<0/>Try adding <1>Home / Following and Notifications</1> first."
msgstr ""

#: src/components/shortcuts-settings.jsx:443
msgid "Max {SHORTCUTS_LIMIT} columns"
msgstr ""

#: src/components/shortcuts-settings.jsx:444
msgid "Max {SHORTCUTS_LIMIT} shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:458
msgid "Import/export"
msgstr ""

#: src/components/shortcuts-settings.jsx:468
msgid "Add column…"
msgstr ""

#: src/components/shortcuts-settings.jsx:469
msgid "Add shortcut…"
msgstr ""

#: src/components/shortcuts-settings.jsx:516
msgid "Specific list is optional. For multi-column mode, list is required, else the column will not be shown."
msgstr ""

#: src/components/shortcuts-settings.jsx:517
msgid "For multi-column mode, search term is required, else the column will not be shown."
msgstr ""

#: src/components/shortcuts-settings.jsx:518
msgid "Multiple hashtags are supported. Space-separated."
msgstr ""

#: src/components/shortcuts-settings.jsx:587
msgid "Edit shortcut"
msgstr ""

#: src/components/shortcuts-settings.jsx:587
msgid "Add shortcut"
msgstr ""

#: src/components/shortcuts-settings.jsx:623
msgid "Timeline"
msgstr ""

#: src/components/shortcuts-settings.jsx:649
msgid "List"
msgstr ""

#: src/components/shortcuts-settings.jsx:788
msgid "Import/Export <0>Shortcuts</0>"
msgstr ""

#: src/components/shortcuts-settings.jsx:798
msgid "Import"
msgstr ""

#: src/components/shortcuts-settings.jsx:806
msgid "Paste shortcuts here"
msgstr ""

#: src/components/shortcuts-settings.jsx:822
msgid "Downloading saved shortcuts from instance server…"
msgstr ""

#: src/components/shortcuts-settings.jsx:851
msgid "Unable to download shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:854
msgid "Download shortcuts from instance server"
msgstr ""

#: src/components/shortcuts-settings.jsx:912
msgid "* Exists in current shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:917
msgid "List may not work if it's from a different account."
msgstr ""

#: src/components/shortcuts-settings.jsx:927
msgid "Invalid settings format"
msgstr ""

#: src/components/shortcuts-settings.jsx:935
msgid "Append to current shortcuts?"
msgstr ""

#: src/components/shortcuts-settings.jsx:938
msgid "Only shortcuts that don’t exist in current shortcuts will be appended."
msgstr ""

#: src/components/shortcuts-settings.jsx:960
msgid "No new shortcuts to import"
msgstr ""

#: src/components/shortcuts-settings.jsx:975
msgid "Shortcuts imported. Exceeded max {SHORTCUTS_LIMIT}, so the rest are not imported."
msgstr ""

#: src/components/shortcuts-settings.jsx:976
#: src/components/shortcuts-settings.jsx:1000
msgid "Shortcuts imported"
msgstr ""

#: src/components/shortcuts-settings.jsx:986
msgid "Import & append…"
msgstr ""

#: src/components/shortcuts-settings.jsx:994
msgid "Override current shortcuts?"
msgstr ""

#: src/components/shortcuts-settings.jsx:995
msgid "Import shortcuts?"
msgstr ""

#: src/components/shortcuts-settings.jsx:1009
msgid "or override…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1009
msgid "Import…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1018
msgid "Export"
msgstr ""

#: src/components/shortcuts-settings.jsx:1033
msgid "Shortcuts copied"
msgstr ""

#: src/components/shortcuts-settings.jsx:1036
msgid "Unable to copy shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:1050
msgid "Shortcut settings copied"
msgstr ""

#: src/components/shortcuts-settings.jsx:1053
msgid "Unable to copy shortcut settings"
msgstr ""

#: src/components/shortcuts-settings.jsx:1083
msgid "Share"
msgstr ""

#: src/components/shortcuts-settings.jsx:1122
msgid "Saving shortcuts to instance server…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1129
msgid "Shortcuts saved"
msgstr ""

#: src/components/shortcuts-settings.jsx:1134
msgid "Unable to save shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:1137
msgid "Sync to instance server"
msgstr ""

#. placeholder {0}: shortcutsStr.length
#: src/components/shortcuts-settings.jsx:1145
msgid "{0, plural, one {# character} other {# characters}}"
msgstr ""

#: src/components/shortcuts-settings.jsx:1157
msgid "Raw Shortcuts JSON"
msgstr ""

#: src/components/shortcuts-settings.jsx:1170
msgid "Import/export settings from/to instance server (Very experimental)"
msgstr ""

#: src/components/status.jsx:571
msgid "<0/> <1>boosted</1>"
msgstr ""

#: src/components/status.jsx:670
msgid "Sorry, your current logged-in instance can't interact with this post from another instance."
msgstr ""

#. placeholder {0}: username || acct
#: src/components/status.jsx:823
msgid "Unliked @{0}'s post"
msgstr ""

#. placeholder {1}: username || acct
#: src/components/status.jsx:824
msgid "Liked @{1}'s post"
msgstr "Liked @{1}'s post"

#. placeholder {2}: username || acct
#: src/components/status.jsx:863
msgid "Unbookmarked @{2}'s post"
msgstr "Unbookmarked @{2}'s post"

#. placeholder {3}: username || acct
#: src/components/status.jsx:864
msgid "Bookmarked @{3}'s post"
msgstr "Bookmarked @{3}'s post"

#: src/components/status.jsx:961
msgid "Some media have no descriptions."
msgstr ""

#. placeholder {0}: rtf.format(-statusMonthsAgo, 'month')
#: src/components/status.jsx:968
msgid "Old post (<0>{0}</0>)"
msgstr ""

#: src/components/status.jsx:992
#: src/components/status.jsx:1032
#: src/components/status.jsx:2450
#: src/components/status.jsx:2473
msgid "Unboost"
msgstr ""

#: src/components/status.jsx:1008
#: src/components/status.jsx:2465
msgid "Quote"
msgstr ""

#. placeholder {4}: username || acct
#: src/components/status.jsx:1020
msgid "Unboosted @{4}'s post"
msgstr "Unboosted @{4}'s post"

#. placeholder {5}: username || acct
#: src/components/status.jsx:1021
msgid "Boosted @{5}'s post"
msgstr "Boosted @{5}'s post"

#: src/components/status.jsx:1033
msgid "Boost…"
msgstr ""

#: src/components/status.jsx:1045
#: src/components/status.jsx:1760
#: src/components/status.jsx:2486
msgid "Unlike"
msgstr ""

#: src/components/status.jsx:1046
#: src/components/status.jsx:1760
#: src/components/status.jsx:1761
#: src/components/status.jsx:2486
#: src/components/status.jsx:2487
msgid "Like"
msgstr ""

#: src/components/status.jsx:1055
#: src/components/status.jsx:2498
msgid "Unbookmark"
msgstr ""

#. placeholder {0}: username || acct
#: src/components/status.jsx:1163
msgid "View post by <0>@{0}</0>"
msgstr ""

#: src/components/status.jsx:1184
msgid "Show Edit History"
msgstr ""

#: src/components/status.jsx:1187
msgid "Edited: {editedDateText}"
msgstr ""

#: src/components/status.jsx:1254
#: src/components/status.jsx:3267
msgid "Embed post"
msgstr ""

#: src/components/status.jsx:1268
msgid "Conversation unmuted"
msgstr ""

#: src/components/status.jsx:1268
msgid "Conversation muted"
msgstr ""

#: src/components/status.jsx:1274
msgid "Unable to unmute conversation"
msgstr ""

#: src/components/status.jsx:1275
msgid "Unable to mute conversation"
msgstr ""

#: src/components/status.jsx:1284
msgid "Unmute conversation"
msgstr ""

#: src/components/status.jsx:1291
msgid "Mute conversation"
msgstr ""

#: src/components/status.jsx:1307
msgid "Post unpinned from profile"
msgstr ""

#: src/components/status.jsx:1308
msgid "Post pinned to profile"
msgstr ""

#: src/components/status.jsx:1313
msgid "Unable to unpin post"
msgstr ""

#: src/components/status.jsx:1313
msgid "Unable to pin post"
msgstr ""

#: src/components/status.jsx:1322
msgid "Unpin from profile"
msgstr ""

#: src/components/status.jsx:1329
msgid "Pin to profile"
msgstr ""

#: src/components/status.jsx:1358
msgid "Delete this post?"
msgstr ""

#: src/components/status.jsx:1374
msgid "Post deleted"
msgstr ""

#: src/components/status.jsx:1377
msgid "Unable to delete post"
msgstr ""

#: src/components/status.jsx:1405
msgid "Report post…"
msgstr ""

#. placeholder {6}: username || acct
#: src/components/status.jsx:1475
msgid "Unboosted @{6}'s post"
msgstr "Unboosted @{6}'s post"

#. placeholder {7}: username || acct
#: src/components/status.jsx:1476
msgid "Boosted @{7}'s post"
msgstr "Boosted @{7}'s post"

#: src/components/status.jsx:1761
#: src/components/status.jsx:1797
#: src/components/status.jsx:2487
msgid "Liked"
msgstr ""

#: src/components/status.jsx:1794
#: src/components/status.jsx:2474
msgid "Boosted"
msgstr ""

#: src/components/status.jsx:1804
#: src/components/status.jsx:2499
msgid "Bookmarked"
msgstr ""

#: src/components/status.jsx:1808
msgid "Pinned"
msgstr ""

#: src/components/status.jsx:1854
#: src/components/status.jsx:2311
msgid "Deleted"
msgstr ""

#: src/components/status.jsx:1895
msgid "{repliesCount, plural, one {# reply} other {# replies}}"
msgstr ""

#. placeholder {0}: snapStates.statusThreadNumber[sKey] ? ` ${snapStates.statusThreadNumber[sKey]}/X` : ''
#: src/components/status.jsx:1985
msgid "Thread{0}"
msgstr ""

#: src/components/status.jsx:2061
#: src/components/status.jsx:2123
#: src/components/status.jsx:2208
msgid "Show less"
msgstr ""

#: src/components/status.jsx:2061
#: src/components/status.jsx:2123
msgid "Show content"
msgstr ""

#: src/components/status.jsx:2208
msgid "Show media"
msgstr ""

#: src/components/status.jsx:2347
msgid "Edited"
msgstr ""

#: src/components/status.jsx:2424
msgid "Comments"
msgstr ""

#. More from [Author]
#: src/components/status.jsx:2725
msgid "More from <0/>"
msgstr "More from <0/>"

#: src/components/status.jsx:3027
msgid "Edit History"
msgstr ""

#: src/components/status.jsx:3031
msgid "Failed to load history"
msgstr ""

#: src/components/status.jsx:3036
#: src/pages/annual-report.jsx:45
msgid "Loading…"
msgstr ""

#: src/components/status.jsx:3272
msgid "HTML Code"
msgstr ""

#: src/components/status.jsx:3289
msgid "HTML code copied"
msgstr ""

#: src/components/status.jsx:3292
msgid "Unable to copy HTML code"
msgstr ""

#: src/components/status.jsx:3304
msgid "Media attachments:"
msgstr ""

#: src/components/status.jsx:3326
msgid "Account Emojis:"
msgstr ""

#: src/components/status.jsx:3357
#: src/components/status.jsx:3402
msgid "static URL"
msgstr ""

#: src/components/status.jsx:3371
msgid "Emojis:"
msgstr ""

#: src/components/status.jsx:3416
msgid "Notes:"
msgstr ""

#: src/components/status.jsx:3420
msgid "This is static, unstyled and scriptless. You may need to apply your own styles and edit as needed."
msgstr ""

#: src/components/status.jsx:3426
msgid "Polls are not interactive, becomes a list with vote counts."
msgstr ""

#: src/components/status.jsx:3431
msgid "Media attachments can be images, videos, audios or any file types."
msgstr ""

#: src/components/status.jsx:3437
msgid "Post could be edited or deleted later."
msgstr ""

#: src/components/status.jsx:3443
msgid "Preview"
msgstr ""

#: src/components/status.jsx:3452
msgid "Note: This preview is lightly styled."
msgstr ""

#. [Name] [Visibility icon] boosted
#: src/components/status.jsx:3696
msgid "<0/> <1/> boosted"
msgstr ""

#: src/components/timeline.jsx:455
#: src/pages/settings.jsx:1171
msgid "New posts"
msgstr ""

#: src/components/timeline.jsx:556
#: src/pages/home.jsx:214
#: src/pages/notifications.jsx:871
#: src/pages/status.jsx:990
#: src/pages/status.jsx:1363
msgid "Try again"
msgstr ""

#. placeholder {0}: fItems.length
#: src/components/timeline.jsx:591
msgid "{0, plural, one {# Boost} other {# Boosts}}"
msgstr "{0, plural, one {# Boost} other {# Boosts}}"

#: src/components/timeline.jsx:596
msgid "Pinned posts"
msgstr "Pinned posts"

#: src/components/timeline.jsx:951
#: src/components/timeline.jsx:958
#: src/pages/catchup.jsx:1894
msgid "Thread"
msgstr ""

#. placeholder {0}: filterInfo.titlesStr
#: src/components/timeline.jsx:973
msgid "<0>Filtered</0>: <1>{0}</1>"
msgstr ""

#: src/components/translation-block.jsx:172
msgid "Auto-translated from {sourceLangText}"
msgstr ""

#: src/components/translation-block.jsx:210
msgid "Translating…"
msgstr ""

#: src/components/translation-block.jsx:213
msgid "Translate from {sourceLangText} (auto-detected)"
msgstr ""

#: src/components/translation-block.jsx:214
msgid "Translate from {sourceLangText}"
msgstr ""

#. placeholder {0}: detectedLang ?? '…'
#: src/components/translation-block.jsx:242
msgid "Auto ({0})"
msgstr ""

#: src/components/translation-block.jsx:255
msgid "Failed to translate"
msgstr ""

#: src/compose.jsx:33
msgid "Editing source status"
msgstr ""

#. placeholder {0}: replyToStatus.account?.acct || replyToStatus.account?.username
#: src/compose.jsx:35
msgid "Replying to @{0}"
msgstr ""

#: src/compose.jsx:63
msgid "You may close this page now."
msgstr ""

#: src/compose.jsx:71
msgid "Close window"
msgstr ""

#: src/compose.jsx:87
msgid "Login required."
msgstr "Login required."

#: src/compose.jsx:91
#: src/pages/annual-report.jsx:164
#: src/pages/http-route.jsx:91
#: src/pages/login.jsx:271
msgid "Go home"
msgstr ""

#: src/pages/account-statuses.jsx:232
msgid "Account posts"
msgstr ""

#: src/pages/account-statuses.jsx:239
msgid "{accountDisplay} (+ Replies)"
msgstr ""

#: src/pages/account-statuses.jsx:241
msgid "{accountDisplay} (- Boosts)"
msgstr ""

#: src/pages/account-statuses.jsx:243
msgid "{accountDisplay} (#{tagged})"
msgstr ""

#: src/pages/account-statuses.jsx:245
msgid "{accountDisplay} (Media)"
msgstr ""

#: src/pages/account-statuses.jsx:251
msgid "{accountDisplay} ({monthYear})"
msgstr ""

#: src/pages/account-statuses.jsx:316
msgid "Clear filters"
msgstr ""

#: src/pages/account-statuses.jsx:319
msgid "Clear"
msgstr ""

#: src/pages/account-statuses.jsx:333
msgid "Showing post with replies"
msgstr ""

#: src/pages/account-statuses.jsx:338
msgid "+ Replies"
msgstr ""

#: src/pages/account-statuses.jsx:344
msgid "Showing posts without boosts"
msgstr ""

#: src/pages/account-statuses.jsx:349
msgid "- Boosts"
msgstr ""

#: src/pages/account-statuses.jsx:355
msgid "Showing posts with media"
msgstr ""

#. placeholder {0}: tag.name
#: src/pages/account-statuses.jsx:372
msgid "Showing posts tagged with #{0}"
msgstr ""

#. placeholder {1}: date.toLocaleString(i18n.locale, { month: 'long', year: 'numeric', })
#: src/pages/account-statuses.jsx:411
msgid "Showing posts in {1}"
msgstr "Showing posts in {1}"

#: src/pages/account-statuses.jsx:500
msgid "Nothing to see here yet."
msgstr ""

#: src/pages/account-statuses.jsx:501
#: src/pages/public.jsx:98
#: src/pages/trending.jsx:450
msgid "Unable to load posts"
msgstr ""

#: src/pages/account-statuses.jsx:542
#: src/pages/account-statuses.jsx:572
msgid "Unable to fetch account info"
msgstr ""

#. placeholder {0}: accountInstance ? ( <> {' '} (<b>{punycode.toUnicode(accountInstance)}</b>) </> ) : null
#: src/pages/account-statuses.jsx:549
msgid "Switch to account's instance {0}"
msgstr ""

#: src/pages/account-statuses.jsx:579
msgid "Switch to my instance (<0>{currentInstance}</0>)"
msgstr ""

#: src/pages/account-statuses.jsx:641
msgid "Month"
msgstr ""

#: src/pages/accounts.jsx:56
msgid "Current"
msgstr ""

#: src/pages/accounts.jsx:102
msgid "Default"
msgstr ""

#: src/pages/accounts.jsx:124
msgid "Switch to this account"
msgstr "Switch to this account"

#: src/pages/accounts.jsx:133
msgid "Switch in new tab/window"
msgstr "Switch in new tab/window"

#: src/pages/accounts.jsx:147
msgid "View profile…"
msgstr ""

#: src/pages/accounts.jsx:164
msgid "Set as default"
msgstr ""

#. placeholder {0}: account.info.acct
#: src/pages/accounts.jsx:174
msgid "Log out <0>@{0}</0>?"
msgstr ""

#: src/pages/accounts.jsx:197
msgid "Log out…"
msgstr ""

#: src/pages/accounts.jsx:210
msgid "Add an existing account"
msgstr ""

#: src/pages/accounts.jsx:217
msgid "Note: <0>Default</0> account will always be used for first load. Switched accounts will persist during the session."
msgstr ""

#: src/pages/bookmarks.jsx:26
msgid "No bookmarks yet. Go bookmark something!"
msgstr "No bookmarks yet. Go bookmark something!"

#: src/pages/bookmarks.jsx:27
msgid "Unable to load bookmarks."
msgstr ""

#: src/pages/catchup.jsx:54
msgid "last 1 hour"
msgstr ""

#: src/pages/catchup.jsx:55
msgid "last 2 hours"
msgstr ""

#: src/pages/catchup.jsx:56
msgid "last 3 hours"
msgstr ""

#: src/pages/catchup.jsx:57
msgid "last 4 hours"
msgstr ""

#: src/pages/catchup.jsx:58
msgid "last 5 hours"
msgstr ""

#: src/pages/catchup.jsx:59
msgid "last 6 hours"
msgstr ""

#: src/pages/catchup.jsx:60
msgid "last 7 hours"
msgstr ""

#: src/pages/catchup.jsx:61
msgid "last 8 hours"
msgstr ""

#: src/pages/catchup.jsx:62
msgid "last 9 hours"
msgstr ""

#: src/pages/catchup.jsx:63
msgid "last 10 hours"
msgstr ""

#: src/pages/catchup.jsx:64
msgid "last 11 hours"
msgstr ""

#: src/pages/catchup.jsx:65
msgid "last 12 hours"
msgstr ""

#: src/pages/catchup.jsx:66
msgid "beyond 12 hours"
msgstr ""

#: src/pages/catchup.jsx:73
msgid "Followed tags"
msgstr ""

#: src/pages/catchup.jsx:74
msgid "Groups"
msgstr ""

#: src/pages/catchup.jsx:596
msgid "Showing {selectedFilterCategory, select, all {all posts} original {original posts} replies {replies} boosts {boosts} followedTags {followed tags} groups {groups} filtered {filtered posts}}, {sortBy, select, createdAt {{sortOrder, select, asc {oldest} desc {latest}}} reblogsCount {{sortOrder, select, asc {fewest boosts} desc {most boosts}}} favouritesCount {{sortOrder, select, asc {fewest likes} desc {most likes}}} repliesCount {{sortOrder, select, asc {fewest replies} desc {most replies}}} density {{sortOrder, select, asc {least dense} desc {most dense}}}} first{groupBy, select, account {, grouped by authors} other {}}"
msgstr "Showing {selectedFilterCategory, select, all {all posts} original {original posts} replies {replies} boosts {boosts} followedTags {followed tags} groups {groups} filtered {filtered posts}}, {sortBy, select, createdAt {{sortOrder, select, asc {oldest} desc {latest}}} reblogsCount {{sortOrder, select, asc {fewest boosts} desc {most boosts}}} favouritesCount {{sortOrder, select, asc {fewest likes} desc {most likes}}} repliesCount {{sortOrder, select, asc {fewest replies} desc {most replies}}} density {{sortOrder, select, asc {least dense} desc {most dense}}}} first{groupBy, select, account {, grouped by authors} other {}}"

#: src/pages/catchup.jsx:882
#: src/pages/catchup.jsx:906
msgid "Catch-up <0>beta</0>"
msgstr ""

#: src/pages/catchup.jsx:896
#: src/pages/catchup.jsx:1585
msgid "Help"
msgstr ""

#: src/pages/catchup.jsx:912
msgid "What is this?"
msgstr ""

#: src/pages/catchup.jsx:915
msgid "Catch-up is a separate timeline for your followings, offering a high-level view at a glance, with a simple, email-inspired interface to effortlessly sort and filter through posts."
msgstr ""

#: src/pages/catchup.jsx:926
msgid "Preview of Catch-up UI"
msgstr "Preview of Catch-up UI"

#: src/pages/catchup.jsx:935
msgid "Let's catch up"
msgstr ""

#: src/pages/catchup.jsx:940
msgid "Let's catch up on the posts from your followings."
msgstr ""

#: src/pages/catchup.jsx:944
msgid "Show me all posts from…"
msgstr ""

#: src/pages/catchup.jsx:967
msgid "until the max"
msgstr "until the max"

#: src/pages/catchup.jsx:997
msgid "Catch up"
msgstr ""

#: src/pages/catchup.jsx:1003
msgid "Overlaps with your last catch-up"
msgstr ""

#. placeholder {0}: dtf.format(new Date(lastCatchupEndAt))
#: src/pages/catchup.jsx:1015
msgid "Until the last catch-up ({0})"
msgstr ""

#: src/pages/catchup.jsx:1024
msgid "Note: your instance might only show a maximum of 800 posts in the Home timeline regardless of the time range. Could be less or more."
msgstr ""

#: src/pages/catchup.jsx:1034
msgid "Previously…"
msgstr ""

#. placeholder {0}: pc.count
#: src/pages/catchup.jsx:1052
msgid "{0, plural, one {# post} other {# posts}}"
msgstr ""

#: src/pages/catchup.jsx:1062
msgid "Remove this catch-up?"
msgstr "Remove this catch-up?"

#. placeholder {0}: pc.id
#: src/pages/catchup.jsx:1065
msgid "Removing Catch-up {0}"
msgstr "Removing Catch-up {0}"

#. placeholder {1}: pc.id
#: src/pages/catchup.jsx:1069
msgid "Catch-up {1} removed"
msgstr "Catch-up {1} removed"

#: src/pages/catchup.jsx:1083
msgid "Note: Only max 3 will be stored. The rest will be automatically removed."
msgstr ""

#: src/pages/catchup.jsx:1098
msgid "Fetching posts…"
msgstr ""

#: src/pages/catchup.jsx:1101
msgid "This might take a while."
msgstr ""

#: src/pages/catchup.jsx:1136
msgid "Reset filters"
msgstr ""

#: src/pages/catchup.jsx:1144
#: src/pages/catchup.jsx:1591
msgid "Top links"
msgstr ""

#. placeholder {0}: sharers.map((s) => { const { avatarStatic, displayName } = s; return ( <button type="button" class="plain" style={{ padding: 0, }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); // Reset and filter to author const { id } = s; setSelectedAuthor(id); setSelectedFilterCategory('all'); }} > <Avatar url={avatarStatic} size="s" alt={displayName} /> </button> ); })
#: src/pages/catchup.jsx:1261
msgid "Shared by {0}"
msgstr ""

#: src/pages/catchup.jsx:1316
#: src/pages/mentions.jsx:148
#: src/pages/search.jsx:313
msgid "All"
msgstr ""

#. placeholder {0}: authorCountsList.length
#: src/pages/catchup.jsx:1401
msgid "{0, plural, one {# author} other {# authors}}"
msgstr ""

#: src/pages/catchup.jsx:1413
msgid "Sort"
msgstr ""

#: src/pages/catchup.jsx:1444
msgid "Date"
msgstr "Date"

#: src/pages/catchup.jsx:1448
msgid "Density"
msgstr "Density"

#. js-lingui-explicit-id
#: src/pages/catchup.jsx:1471
msgid "group.filter"
msgstr "Group"

#: src/pages/catchup.jsx:1486
msgid "Authors"
msgstr "Authors"

#: src/pages/catchup.jsx:1487
msgid "None"
msgstr "None"

#: src/pages/catchup.jsx:1503
msgid "Show all authors"
msgstr ""

#: src/pages/catchup.jsx:1554
msgid "You don't have to read everything."
msgstr "You don't have to read everything."

#: src/pages/catchup.jsx:1555
msgid "That's all."
msgstr "That's all."

#: src/pages/catchup.jsx:1563
msgid "Back to top"
msgstr ""

#: src/pages/catchup.jsx:1594
msgid "Links shared by followings, sorted by shared counts, boosts and likes."
msgstr ""

#: src/pages/catchup.jsx:1600
msgid "Sort: Density"
msgstr ""

#: src/pages/catchup.jsx:1603
msgid "Posts are sorted by information density or depth. Shorter posts are \"lighter\" while longer posts are \"heavier\". Posts with photos are \"heavier\" than posts without photos."
msgstr ""

#: src/pages/catchup.jsx:1610
msgid "Group: Authors"
msgstr ""

#: src/pages/catchup.jsx:1613
msgid "Posts are grouped by authors, sorted by posts count per author."
msgstr ""

#: src/pages/catchup.jsx:1660
msgid "Next author"
msgstr ""

#: src/pages/catchup.jsx:1668
msgid "Previous author"
msgstr ""

#: src/pages/catchup.jsx:1684
msgid "Scroll to top"
msgstr ""

#. placeholder {0}: filterInfo.titlesStr
#: src/pages/catchup.jsx:1876
msgid "Filtered: {0}"
msgstr "Filtered: {0}"

#: src/pages/favourites.jsx:26
msgid "No likes yet. Go like something!"
msgstr "No likes yet. Go like something!"

#: src/pages/favourites.jsx:27
msgid "Unable to load likes."
msgstr ""

#: src/pages/filters.jsx:22
msgid "Home and lists"
msgstr ""

#: src/pages/filters.jsx:24
msgid "Public timelines"
msgstr ""

#: src/pages/filters.jsx:25
msgid "Conversations"
msgstr ""

#: src/pages/filters.jsx:26
msgid "Profiles"
msgstr ""

#: src/pages/filters.jsx:41
msgid "Never"
msgstr ""

#: src/pages/filters.jsx:103
#: src/pages/filters.jsx:228
msgid "New filter"
msgstr ""

#. placeholder {0}: filters.length
#: src/pages/filters.jsx:151
msgid "{0, plural, one {# filter} other {# filters}}"
msgstr ""

#: src/pages/filters.jsx:166
msgid "Unable to load filters."
msgstr ""

#: src/pages/filters.jsx:170
msgid "No filters yet."
msgstr ""

#: src/pages/filters.jsx:177
msgid "Add filter"
msgstr ""

#: src/pages/filters.jsx:228
msgid "Edit filter"
msgstr ""

#: src/pages/filters.jsx:345
msgid "Unable to edit filter"
msgstr ""

#: src/pages/filters.jsx:346
msgid "Unable to create filter"
msgstr ""

#: src/pages/filters.jsx:355
msgid "Title"
msgstr ""

#: src/pages/filters.jsx:396
msgid "Whole word"
msgstr ""

#: src/pages/filters.jsx:422
msgid "No keywords. Add one."
msgstr ""

#: src/pages/filters.jsx:449
msgid "Add keyword"
msgstr ""

#. placeholder {0}: filteredEditKeywords.length
#: src/pages/filters.jsx:453
msgid "{0, plural, one {# keyword} other {# keywords}}"
msgstr ""

#: src/pages/filters.jsx:466
msgid "Filter from…"
msgstr ""

#: src/pages/filters.jsx:492
msgid "* Not implemented yet"
msgstr ""

#: src/pages/filters.jsx:498
msgid "Status: <0><1/></0>"
msgstr ""

#: src/pages/filters.jsx:507
msgid "Change expiry"
msgstr ""

#: src/pages/filters.jsx:507
msgid "Expiry"
msgstr ""

#: src/pages/filters.jsx:526
msgid "Filtered post will be…"
msgstr ""

#: src/pages/filters.jsx:536
msgid "minimized"
msgstr ""

#: src/pages/filters.jsx:546
msgid "hidden"
msgstr ""

#: src/pages/filters.jsx:563
msgid "Delete this filter?"
msgstr ""

#: src/pages/filters.jsx:576
msgid "Unable to delete filter."
msgstr ""

#: src/pages/filters.jsx:609
msgid "Expired"
msgstr ""

#: src/pages/filters.jsx:611
msgid "Expiring <0/>"
msgstr ""

#: src/pages/filters.jsx:615
msgid "Never expires"
msgstr ""

#. placeholder {0}: followedHashtags.length
#: src/pages/followed-hashtags.jsx:71
msgid "{0, plural, one {# hashtag} other {# hashtags}}"
msgstr ""

#: src/pages/followed-hashtags.jsx:86
msgid "Unable to load followed hashtags."
msgstr ""

#: src/pages/followed-hashtags.jsx:90
msgid "No hashtags followed yet."
msgstr ""

#: src/pages/following.jsx:144
msgid "Nothing to see here."
msgstr ""

#: src/pages/following.jsx:145
#: src/pages/list.jsx:109
msgid "Unable to load posts."
msgstr ""

#: src/pages/hashtag.jsx:56
msgid "{hashtagTitle} (Media only) on {instance}"
msgstr ""

#: src/pages/hashtag.jsx:57
msgid "{hashtagTitle} on {instance}"
msgstr ""

#: src/pages/hashtag.jsx:59
msgid "{hashtagTitle} (Media only)"
msgstr ""

#: src/pages/hashtag.jsx:60
msgid "{hashtagTitle}"
msgstr ""

#: src/pages/hashtag.jsx:182
msgid "No one has posted anything with this tag yet."
msgstr ""

#: src/pages/hashtag.jsx:183
msgid "Unable to load posts with this tag"
msgstr ""

#: src/pages/hashtag.jsx:209
msgid "Unfollow #{hashtag}?"
msgstr "Unfollow #{hashtag}?"

#: src/pages/hashtag.jsx:224
msgid "Unfollowed #{hashtag}"
msgstr ""

#: src/pages/hashtag.jsx:239
msgid "Followed #{hashtag}"
msgstr ""

#: src/pages/hashtag.jsx:255
msgid "Following…"
msgstr ""

#: src/pages/hashtag.jsx:283
msgid "Unfeatured on profile"
msgstr ""

#: src/pages/hashtag.jsx:297
msgid "Unable to unfeature on profile"
msgstr ""

#: src/pages/hashtag.jsx:306
#: src/pages/hashtag.jsx:322
msgid "Featured on profile"
msgstr ""

#: src/pages/hashtag.jsx:329
msgid "Feature on profile"
msgstr ""

#: src/pages/hashtag.jsx:394
msgid "{TOTAL_TAGS_LIMIT, plural, other {Max # tags}}"
msgstr ""

#: src/pages/hashtag.jsx:397
msgid "Add hashtag"
msgstr ""

#: src/pages/hashtag.jsx:429
msgid "Remove hashtag"
msgstr ""

#: src/pages/hashtag.jsx:443
msgid "{SHORTCUTS_LIMIT, plural, one {Max # shortcut reached. Unable to add shortcut.} other {Max # shortcuts reached. Unable to add shortcut.}}"
msgstr ""

#: src/pages/hashtag.jsx:472
msgid "This shortcut already exists"
msgstr ""

#: src/pages/hashtag.jsx:475
msgid "Hashtag shortcut added"
msgstr ""

#: src/pages/hashtag.jsx:481
msgid "Add to Shortcuts"
msgstr ""

#: src/pages/hashtag.jsx:487
#: src/pages/public.jsx:140
#: src/pages/trending.jsx:479
msgid "Enter a new instance e.g. \"mastodon.social\""
msgstr ""

#: src/pages/hashtag.jsx:490
#: src/pages/public.jsx:143
#: src/pages/trending.jsx:482
msgid "Invalid instance"
msgstr ""

#: src/pages/hashtag.jsx:504
#: src/pages/public.jsx:157
#: src/pages/trending.jsx:494
msgid "Go to another instance…"
msgstr ""

#: src/pages/hashtag.jsx:517
#: src/pages/public.jsx:170
#: src/pages/trending.jsx:505
msgid "Go to my instance (<0>{currentInstance}</0>)"
msgstr ""

#: src/pages/home.jsx:210
msgid "Unable to fetch notifications."
msgstr ""

#: src/pages/home.jsx:230
msgid "<0>New</0> <1>Follow Requests</1>"
msgstr ""

#: src/pages/home.jsx:236
msgid "See all"
msgstr ""

#: src/pages/http-route.jsx:68
msgid "Resolving…"
msgstr ""

#: src/pages/http-route.jsx:79
msgid "Unable to resolve URL"
msgstr ""

#: src/pages/list.jsx:108
msgid "Nothing yet."
msgstr ""

#: src/pages/list.jsx:177
#: src/pages/list.jsx:281
msgid "Manage members"
msgstr ""

#. placeholder {0}: account.username
#: src/pages/list.jsx:316
msgid "Remove <0>@{0}</0> from list?"
msgstr ""

#: src/pages/list.jsx:362
msgid "Remove…"
msgstr ""

#. placeholder {0}: lists.length
#: src/pages/lists.jsx:94
msgid "{0, plural, one {# list} other {# lists}}"
msgstr ""

#: src/pages/lists.jsx:109
msgid "No lists yet."
msgstr ""

#: src/pages/login.jsx:110
#: src/pages/login.jsx:123
msgid "Failed to register application"
msgstr "Failed to register application"

#: src/pages/login.jsx:209
msgid "instance domain"
msgstr "instance domain"

#: src/pages/login.jsx:233
msgid "e.g. “mastodon.social”"
msgstr ""

#: src/pages/login.jsx:244
msgid "Failed to log in. Please try again or try another instance."
msgstr ""

#: src/pages/login.jsx:256
msgid "Continue with {selectedInstanceText}"
msgstr ""

#: src/pages/login.jsx:257
msgid "Continue"
msgstr ""

#: src/pages/login.jsx:265
msgid "Don't have an account? Create one!"
msgstr ""

#: src/pages/mentions.jsx:21
msgid "Private mentions"
msgstr ""

#: src/pages/mentions.jsx:160
msgid "Private"
msgstr ""

#: src/pages/mentions.jsx:170
msgid "No one mentioned you :("
msgstr ""

#: src/pages/mentions.jsx:171
msgid "Unable to load mentions."
msgstr ""

#: src/pages/notifications.jsx:104
msgid "You don't follow"
msgstr ""

#: src/pages/notifications.jsx:105
msgid "Who don't follow you"
msgstr ""

#: src/pages/notifications.jsx:106
msgid "With a new account"
msgstr ""

#: src/pages/notifications.jsx:107
msgid "Who unsolicitedly private mention you"
msgstr ""

#: src/pages/notifications.jsx:108
msgid "Who are limited by server moderators"
msgstr ""

#: src/pages/notifications.jsx:578
#: src/pages/notifications.jsx:919
msgid "Notifications settings"
msgstr ""

#: src/pages/notifications.jsx:596
msgid "New notifications"
msgstr ""

#. placeholder {0}: announcements.length
#: src/pages/notifications.jsx:607
msgid "{0, plural, one {Announcement} other {Announcements}}"
msgstr ""

#: src/pages/notifications.jsx:654
#: src/pages/settings.jsx:1159
msgid "Follow requests"
msgstr ""

#. placeholder {0}: followRequests.length
#: src/pages/notifications.jsx:659
msgid "{0, plural, one {# follow request} other {# follow requests}}"
msgstr ""

#. placeholder {0}: notificationsPolicy.summary.pendingRequestsCount
#: src/pages/notifications.jsx:714
msgid "{0, plural, one {Filtered notifications from # person} other {Filtered notifications from # people}}"
msgstr ""

#: src/pages/notifications.jsx:787
msgid "Only mentions"
msgstr ""

#: src/pages/notifications.jsx:791
msgid "Today"
msgstr ""

#: src/pages/notifications.jsx:796
msgid "You're all caught up."
msgstr ""

#: src/pages/notifications.jsx:819
msgid "Yesterday"
msgstr ""

#: src/pages/notifications.jsx:867
msgid "Unable to load notifications"
msgstr ""

#: src/pages/notifications.jsx:946
msgid "Notifications settings updated"
msgstr ""

#: src/pages/notifications.jsx:954
msgid "Filter out notifications from people:"
msgstr ""

#: src/pages/notifications.jsx:968
msgid "Filter"
msgstr ""

#: src/pages/notifications.jsx:971
msgid "Ignore"
msgstr ""

#. placeholder {0}: niceDateTime(updatedAtDate)
#: src/pages/notifications.jsx:1044
msgid "Updated <0>{0}</0>"
msgstr ""

#. placeholder {0}: account.username
#: src/pages/notifications.jsx:1112
msgid "View notifications from <0>@{0}</0>"
msgstr ""

#. placeholder {0}: account.username
#: src/pages/notifications.jsx:1133
msgid "Notifications from <0>@{0}</0>"
msgstr ""

#. placeholder {0}: request.account.username
#: src/pages/notifications.jsx:1201
msgid "Notifications from @{0} will not be filtered from now on."
msgstr ""

#: src/pages/notifications.jsx:1206
msgid "Unable to accept notification request"
msgstr ""

#: src/pages/notifications.jsx:1211
msgid "Allow"
msgstr ""

#. placeholder {1}: request.account.username
#: src/pages/notifications.jsx:1231
msgid "Notifications from @{1} will not show up in Filtered notifications from now on."
msgstr "Notifications from @{1} will not show up in Filtered notifications from now on."

#: src/pages/notifications.jsx:1236
msgid "Unable to dismiss notification request"
msgstr ""

#: src/pages/notifications.jsx:1241
msgid "Dismiss"
msgstr ""

#: src/pages/notifications.jsx:1256
msgid "Dismissed"
msgstr ""

#: src/pages/public.jsx:28
msgid "Local timeline ({instance})"
msgstr ""

#: src/pages/public.jsx:29
msgid "Federated timeline ({instance})"
msgstr ""

#: src/pages/public.jsx:91
msgid "Local timeline"
msgstr ""

#: src/pages/public.jsx:91
msgid "Federated timeline"
msgstr ""

#: src/pages/public.jsx:97
msgid "No one has posted anything yet."
msgstr ""

#: src/pages/public.jsx:124
msgid "Switch to Federated"
msgstr "Switch to Federated"

#: src/pages/public.jsx:131
msgid "Switch to Local"
msgstr "Switch to Local"

#: src/pages/scheduled-posts.jsx:108
msgid "No scheduled posts."
msgstr "No scheduled posts."

#. Scheduled [in 1 day] ([Thu, Feb 27, 6:30:00 PM])
#. placeholder {0}: niceDateTime(scheduledAt, { formatOpts: { weekday: 'short', second: 'numeric', }, })
#: src/pages/scheduled-posts.jsx:205
msgid "Scheduled <0><1/></0> <2>({0})</2>"
msgstr "Scheduled <0><1/></0> <2>({0})</2>"

#. Scheduled [in 1 day]
#: src/pages/scheduled-posts.jsx:261
msgid "Scheduled <0><1/></0>"
msgstr "Scheduled <0><1/></0>"

#: src/pages/scheduled-posts.jsx:306
msgid "Scheduled post rescheduled"
msgstr "Scheduled post rescheduled"

#: src/pages/scheduled-posts.jsx:313
msgid "Failed to reschedule post"
msgstr "Failed to reschedule post"

#: src/pages/scheduled-posts.jsx:336
msgid "Reschedule"
msgstr "Reschedule"

#: src/pages/scheduled-posts.jsx:342
msgid "Delete scheduled post?"
msgstr "Delete scheduled post?"

#: src/pages/scheduled-posts.jsx:350
msgid "Scheduled post deleted"
msgstr "Scheduled post deleted"

#: src/pages/scheduled-posts.jsx:357
msgid "Failed to delete scheduled post"
msgstr "Failed to delete scheduled post"

#: src/pages/search.jsx:50
msgid "Search: {q} (Posts)"
msgstr ""

#: src/pages/search.jsx:53
msgid "Search: {q} (Accounts)"
msgstr ""

#: src/pages/search.jsx:56
msgid "Search: {q} (Hashtags)"
msgstr ""

#: src/pages/search.jsx:59
msgid "Search: {q}"
msgstr ""

#: src/pages/search.jsx:323
#: src/pages/search.jsx:405
msgid "Hashtags"
msgstr ""

#: src/pages/search.jsx:355
#: src/pages/search.jsx:409
#: src/pages/search.jsx:479
msgid "See more"
msgstr ""

#: src/pages/search.jsx:381
msgid "See more accounts"
msgstr ""

#: src/pages/search.jsx:395
msgid "No accounts found."
msgstr ""

#: src/pages/search.jsx:451
msgid "See more hashtags"
msgstr ""

#: src/pages/search.jsx:465
msgid "No hashtags found."
msgstr ""

#: src/pages/search.jsx:509
msgid "See more posts"
msgstr ""

#: src/pages/search.jsx:523
msgid "No posts found."
msgstr ""

#: src/pages/search.jsx:567
msgid "Enter your search term or paste a URL above to get started."
msgstr ""

#: src/pages/settings.jsx:83
msgid "Settings"
msgstr ""

#: src/pages/settings.jsx:92
msgid "Appearance"
msgstr ""

#: src/pages/settings.jsx:168
msgid "Light"
msgstr ""

#: src/pages/settings.jsx:179
msgid "Dark"
msgstr ""

#: src/pages/settings.jsx:192
msgid "Auto"
msgstr ""

#: src/pages/settings.jsx:202
msgid "Text size"
msgstr ""

#. Preview of one character, in smallest size
#. Preview of one character, in largest size
#: src/pages/settings.jsx:207
#: src/pages/settings.jsx:232
msgid "A"
msgstr ""

#: src/pages/settings.jsx:246
msgid "Display language"
msgstr ""

#: src/pages/settings.jsx:255
msgid "Volunteer translations"
msgstr "Volunteer translations"

#: src/pages/settings.jsx:266
msgid "Posting"
msgstr ""

#: src/pages/settings.jsx:273
msgid "Default visibility"
msgstr ""

#: src/pages/settings.jsx:274
#: src/pages/settings.jsx:320
msgid "Synced"
msgstr ""

#: src/pages/settings.jsx:299
msgid "Failed to update posting privacy"
msgstr ""

#: src/pages/settings.jsx:322
msgid "Synced to your instance server's settings. <0>Go to your instance ({instance}) for more settings.</0>"
msgstr ""

#: src/pages/settings.jsx:337
msgid "Experiments"
msgstr ""

#: src/pages/settings.jsx:350
msgid "Auto refresh timeline posts"
msgstr ""

#: src/pages/settings.jsx:362
msgid "Boosts carousel"
msgstr ""

#: src/pages/settings.jsx:378
msgid "Post translation"
msgstr ""

#: src/pages/settings.jsx:389
msgid "Translate to "
msgstr "Translate to "

#: src/pages/settings.jsx:400
msgid "System language ({systemTargetLanguageText})"
msgstr ""

#. placeholder {0}: snapStates.settings.contentTranslationHideLanguages.length
#: src/pages/settings.jsx:426
msgid "{0, plural, =0 {Hide \"Translate\" button for:} other {Hide \"Translate\" button for (#):}}"
msgstr ""

#: src/pages/settings.jsx:480
msgid "Note: This feature uses external translation services, powered by <0>Lingva API</0> & <1>Lingva Translate</1>."
msgstr ""

#: src/pages/settings.jsx:514
msgid "Auto inline translation"
msgstr ""

#: src/pages/settings.jsx:518
msgid "Automatically show translation for posts in timeline. Only works for <0>short</0> posts without content warning, media and poll."
msgstr ""

#: src/pages/settings.jsx:538
msgid "GIF Picker for composer"
msgstr ""

#: src/pages/settings.jsx:542
msgid "Note: This feature uses external GIF search service, powered by <0>GIPHY</0>. G-rated (suitable for viewing by all ages), tracking parameters are stripped, referrer information is omitted from requests, but search queries and IP address information will still reach their servers."
msgstr ""

#: src/pages/settings.jsx:571
msgid "Image description generator"
msgstr ""

#: src/pages/settings.jsx:576
msgid "Only for new images while composing new posts."
msgstr ""

#: src/pages/settings.jsx:583
msgid "Note: This feature uses external AI service, powered by <0>img-alt-api</0>. May not work well. Only for images and in English."
msgstr ""

#: src/pages/settings.jsx:609
msgid "Server-side grouped notifications"
msgstr ""

#: src/pages/settings.jsx:613
msgid "Alpha-stage feature. Potentially improved grouping window but basic grouping logic."
msgstr ""

#: src/pages/settings.jsx:634
msgid "\"Cloud\" import/export for shortcuts settings"
msgstr ""

#: src/pages/settings.jsx:639
msgid "⚠️⚠️⚠️ Very experimental.<0/>Stored in your own profile’s notes. Profile (private) notes are mainly used for other profiles, and hidden for own profile."
msgstr ""

#: src/pages/settings.jsx:650
msgid "Note: This feature uses currently-logged-in instance server API."
msgstr ""

#: src/pages/settings.jsx:667
msgid "Cloak mode <0>(<1>Text</1> → <2>████</2>)</0>"
msgstr ""

#: src/pages/settings.jsx:676
msgid "Replace text as blocks, useful when taking screenshots, for privacy reasons."
msgstr ""

#: src/pages/settings.jsx:701
msgid "About"
msgstr ""

#: src/pages/settings.jsx:740
msgid "<0>Built</0> by <1>@cheeaun</1>"
msgstr ""

#: src/pages/settings.jsx:769
msgid "Sponsor"
msgstr ""

#: src/pages/settings.jsx:777
msgid "Donate"
msgstr "Donate"

#: src/pages/settings.jsx:789
msgid "Privacy Policy"
msgstr ""

#. placeholder {0}: WEBSITE.replace(/https?:\/\//g, '').replace(/\/$/, '')
#: src/pages/settings.jsx:796
msgid "<0>Site:</0> {0}"
msgstr ""

#. placeholder {0}: !__FAKE_COMMIT_HASH__ && ( <span class="ib insignificant"> ( <a href={`https://github.com/cheeaun/phanpy/commit/${__COMMIT_HASH__}`} target="_blank" rel="noopener" > <RelativeTime datetime={new Date(__BUILD_TIME__)} /> </a> ) </span> )
#: src/pages/settings.jsx:803
msgid "<0>Version:</0> <1/> {0}"
msgstr ""

#: src/pages/settings.jsx:818
msgid "Version string copied"
msgstr ""

#: src/pages/settings.jsx:821
msgid "Unable to copy version string"
msgstr ""

#: src/pages/settings.jsx:1056
#: src/pages/settings.jsx:1061
msgid "Failed to update subscription. Please try again."
msgstr ""

#: src/pages/settings.jsx:1067
msgid "Failed to remove subscription. Please try again."
msgstr ""

#: src/pages/settings.jsx:1074
msgid "Push Notifications (beta)"
msgstr ""

#: src/pages/settings.jsx:1096
msgid "Push notifications are blocked. Please enable them in your browser settings."
msgstr ""

#. placeholder {0}: [ { value: 'all', label: t`anyone`, }, { value: 'followed', label: t`people I follow`, }, { value: 'follower', label: t`followers`, }, ].map((type) => ( <option value={type.value}>{type.label}</option> ))
#: src/pages/settings.jsx:1105
msgid "Allow from <0>{0}</0>"
msgstr ""

#: src/pages/settings.jsx:1114
msgid "anyone"
msgstr ""

#: src/pages/settings.jsx:1118
msgid "people I follow"
msgstr ""

#: src/pages/settings.jsx:1122
msgid "followers"
msgstr ""

#: src/pages/settings.jsx:1155
msgid "Follows"
msgstr ""

#: src/pages/settings.jsx:1163
msgid "Polls"
msgstr ""

#: src/pages/settings.jsx:1167
msgid "Post edits"
msgstr ""

#: src/pages/settings.jsx:1188
msgid "Push permission was not granted since your last login. You'll need to <0><1>log in</1> again to grant push permission</0>."
msgstr ""

#: src/pages/settings.jsx:1204
msgid "NOTE: Push notifications only work for <0>one account</0>."
msgstr ""

#. js-lingui-explicit-id
#: src/pages/status.jsx:599
#: src/pages/status.jsx:1133
msgid "post.title"
msgstr "Post"

#: src/pages/status.jsx:824
msgid "You're not logged in. Interactions (reply, boost, etc) are not possible."
msgstr ""

#: src/pages/status.jsx:844
msgid "This post is from another instance (<0>{instance}</0>). Interactions (reply, boost, etc) are not possible."
msgstr ""

#: src/pages/status.jsx:872
msgid "Error: {e}"
msgstr ""

#: src/pages/status.jsx:879
msgid "Switch to my instance to enable interactions"
msgstr ""

#: src/pages/status.jsx:981
msgid "Unable to load replies."
msgstr ""

#: src/pages/status.jsx:1093
msgid "Back"
msgstr ""

#: src/pages/status.jsx:1124
msgid "Go to main post"
msgstr ""

#. placeholder {0}: ancestors.length
#: src/pages/status.jsx:1147
msgid "{0} posts above ‒ Go to top"
msgstr ""

#: src/pages/status.jsx:1190
#: src/pages/status.jsx:1253
msgid "Switch to Side Peek view"
msgstr ""

#: src/pages/status.jsx:1254
msgid "Switch to Full view"
msgstr ""

#: src/pages/status.jsx:1272
msgid "Show all sensitive content"
msgstr ""

#: src/pages/status.jsx:1277
msgid "Experimental"
msgstr ""

#: src/pages/status.jsx:1286
msgid "Unable to switch"
msgstr ""

#. placeholder {1}: punycode.toUnicode( postInstance, )
#: src/pages/status.jsx:1293
msgid "Switch to post's instance ({1})"
msgstr "Switch to post's instance ({1})"

#: src/pages/status.jsx:1296
msgid "Switch to post's instance"
msgstr ""

#: src/pages/status.jsx:1354
msgid "Unable to load post"
msgstr ""

#. placeholder {0}: replies.length
#. placeholder {1}: shortenNumber(replies.length)
#: src/pages/status.jsx:1490
msgid "{0, plural, one {# reply} other {<0>{1}</0> replies}}"
msgstr ""

#. placeholder {0}: shortenNumber(totalComments)
#: src/pages/status.jsx:1508
msgid "{totalComments, plural, one {# comment} other {<0>{0}</0> comments}}"
msgstr ""

#: src/pages/status.jsx:1530
msgid "View post with its replies"
msgstr ""

#: src/pages/trending.jsx:72
msgid "Trending ({instance})"
msgstr ""

#: src/pages/trending.jsx:229
msgid "Trending News"
msgstr ""

#. By [Author]
#. placeholder {0}: author ? ( <NameText account={author} showAvatar /> ) : authorUrl ? ( <a href={authorUrl} target="_blank" rel="noopener" > {authorName} </a> ) : ( authorName )
#: src/pages/trending.jsx:348
msgid "By {0}"
msgstr "By {0}"

#: src/pages/trending.jsx:409
msgid "Back to showing trending posts"
msgstr ""

#. placeholder {0}: currentLink .replace(/^https?:\/\/(www\.)?/i, '') .replace(/\/$/, '')
#: src/pages/trending.jsx:414
msgid "Showing posts mentioning <0>{0}</0>"
msgstr ""

#: src/pages/trending.jsx:426
msgid "Trending posts"
msgstr ""

#: src/pages/trending.jsx:449
msgid "No trending posts."
msgstr ""

#: src/pages/welcome.jsx:54
msgid "A minimalistic opinionated Mastodon web client."
msgstr ""

#: src/pages/welcome.jsx:65
msgid "Log in with Mastodon"
msgstr ""

#: src/pages/welcome.jsx:71
msgid "Sign up"
msgstr ""

#: src/pages/welcome.jsx:78
msgid "Connect your existing Mastodon/Fediverse account.<0/>Your credentials are not stored on this server."
msgstr ""

#: src/pages/welcome.jsx:95
msgid "<0>Built</0> by <1>@cheeaun</1>. <2>Privacy Policy</2>."
msgstr ""

#: src/pages/welcome.jsx:126
msgid "Screenshot of Boosts Carousel"
msgstr ""

#: src/pages/welcome.jsx:130
msgid "Boosts Carousel"
msgstr ""

#: src/pages/welcome.jsx:133
msgid "Visually separate original posts and re-shared posts (boosted posts)."
msgstr ""

#: src/pages/welcome.jsx:142
msgid "Screenshot of nested comments thread"
msgstr ""

#: src/pages/welcome.jsx:146
msgid "Nested comments thread"
msgstr ""

#: src/pages/welcome.jsx:149
msgid "Effortlessly follow conversations. Semi-collapsible replies."
msgstr ""

#: src/pages/welcome.jsx:157
msgid "Screenshot of grouped notifications"
msgstr ""

#: src/pages/welcome.jsx:161
msgid "Grouped notifications"
msgstr ""

#: src/pages/welcome.jsx:164
msgid "Similar notifications are grouped and collapsed to reduce clutter."
msgstr ""

#: src/pages/welcome.jsx:173
msgid "Screenshot of multi-column UI"
msgstr ""

#: src/pages/welcome.jsx:177
msgid "Single or multi-column"
msgstr ""

#: src/pages/welcome.jsx:180
msgid "By default, single column for zen-mode seekers. Configurable multi-column for power users."
msgstr ""

#: src/pages/welcome.jsx:189
msgid "Screenshot of multi-hashtag timeline with a form to add more hashtags"
msgstr ""

#: src/pages/welcome.jsx:193
msgid "Multi-hashtag timeline"
msgstr ""

#: src/pages/welcome.jsx:196
msgid "Up to 5 hashtags combined into a single timeline."
msgstr ""

#: src/utils/open-compose.js:24
msgid "Looks like your browser is blocking popups."
msgstr ""

#: src/utils/show-compose.js:16
msgid "A draft post is currently minimized. Post or discard it before creating a new one."
msgstr ""

#: src/utils/show-compose.js:21
msgid "A post is currently open. Post or discard it before creating a new one."
msgstr ""
